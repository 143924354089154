import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './GrowthQuotientIndex.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/GQI-Loop.gif'

const GrowthQuotientIndex = () =>{
    return(
        <div className={styles.body}>
       

        <Container fluid  >
            
            <Row >
                
            <Col lg={6} style={{paddingLeft:"4vw", marginTop:"15px"}} className={`${styles.HandlingContent} order-lg-1 order-3`}
>
                    <div style={{ display:"flex", justifyContent:"flex-start", }}>
                    <h2 className={styles.winergeSummaryHeadline}> <span style={{color:"#FBB03B"}}> Growth Quotient Index(GQI)</span></h2>
                    </div>
                    <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"1vh" }}>
                    <h4 > <span style={{color:"#FBB03B"}}> GQI</span> <span style={{color:"white"}}>gives the assement of child's cognitive, socio-emotional, and adaptive skills development relative to the age group</span> </h4>
                    </div>
                    <div>
            <ul>
                <li className={styles.winergeSummaryContent} style={{paddingTop:"3vh"}}>Success Multiplier, a certified child development expert, uses the teacher input, parent insights, and child's self evaluation to build up GQI</li>
                <li className={styles.winergeSummaryContent}> Success Multiplier then pin point strengths and create a growth plan</li>
                <li className={styles.winergeSummaryContent}>Success Multiplier acts as a bridge between school & home, leveraging the power of collaboration</li>
            </ul>
        </div>

        {/* <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"6vh" }}>
                    <h4 > <span style={{color:"#FBB03B"}}> Success Multiplier</span> <span style={{color:"white"}}>are qualified trainers in child development, trained for WinGS</span> </h4>
                    </div> */}
                                        
                </Col>

                <Col lg={6} className="order-lg-1 order-1" style= {{paddingTop:"8vh"}}>
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>

            </Row>
        </Container>
    </div>
    );
}

export default GrowthQuotientIndex;