import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SubstackFeed = () => {
  
  useEffect(() => {
    window.SubstackFeedWidget = {
      substackUrl: "winerge.substack.com",
      posts: 3,
      filter: "top",
      layout: "right",
      hidden: ["reactions", "date", "comments", "author"],
      colors: {
        primary: "#474747",
        secondary: "#808080",
        background: "#FFFFFF",
      }
    };

    // Load the script dynamically
    const script = document.createElement('script');
    script.src = "https://substackapi.com/embeds/feed.js";
    script.async = true;
    document.body.appendChild(script);

    // Optional: You can remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };

  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>     
        <Container>
            <Row style={{marginTop:"5vh"}}>
                <Col lg={2}></Col>
                <Col lg={8}>
                <div id="substack-feed-embed" ></div>
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4vh' }}>
      <iframe 
        src="https://winerge.substack.com/embed" 
        width="auto" 
        height="auto" 
        style={{ border: '1px solid #EEE', background: 'white' }} 
        frameBorder="0" 
        scrolling="no"
      ></iframe>
    </div>
                </Col>
                <Col lg={2}></Col>
            </Row>

        </Container>
         
    </>
   
  );
};

export default SubstackFeed;
