import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './GrowthParameter.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/5Parameters.png'

const GrowthParameter = () =>{ 

    return(
        <div className={styles.body}>
       

        <Container fluid >
            
            <Row >
                <Col lg={6} className="pb-0 mb-0" style= {{paddingTop:"12vh"}}>
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>

                <Col lg={6} style={{ marginTop:"15px"}} className={styles.HandlingContent}>
                    <div style={{ display:"flex", justifyContent:"flex-start", }}>
                    <h2 className={styles.winergeSummaryHeadline}> <span style={{color:"#FBB03B"}}>Growth Parameters</span></h2>
                    </div>

                    <div>
            <ul>
                <li className={styles.winergeSummaryContent} style={{paddingTop:"3vh"}}>Growth Parameters reflect core skills and characteristics that influence long term achievement</li>
                <li className={styles.winergeSummaryContent}>Our approach changes with your child's age. We target the growth parameters most important for their developmental stage</li>
                <li className={styles.winergeSummaryContent}>Research shows that kids with strong growth parameters are more likely to excel in careers, socially, academically, and in general in life.</li>
            </ul>
        </div>

        <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"12vh" }}>
                    <h4 > <span style={{color:"#FBB03B"}}> Here at WinGS</span> <span style={{color:"white"}}>we currently focus on 2 Growth Parameters: Socio-Emotional and Cognitive</span> </h4>
                    </div>
                                        
                </Col>

            </Row>
        </Container>
    </div>
    );
}

export default GrowthParameter;