import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './CognitiveGrowth.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/CognitiveParams.png'

const CognitiveGrowth = () =>{
    return (
        <div className={styles.body}>
       

        <Container fluid >
            
            <Row >
            <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"5vh", paddingLeft:"4vw"}}>
                    <h2 className={styles.winergeSummaryHeadline}> <span style={{color:"#FBB03B"}}>Cognitive Capability</span></h2>
                    </div>
                    
                <Col lg={6} style={{ paddingLeft:"4vw"}} className={`${styles.HandlingContent} order-lg-1 order-3`}
>
                    

                    <div>
            <ul>
                <li className={styles.winergeSummaryContent} style ={{ paddingTop:"2.5vh"}} >Information Analysis</li>
                <li className={styles.winergeSummaryContent}>Judgement Formation</li>
                <li className={styles.winergeSummaryContent}>Visual Memory</li>
                <li className={styles.winergeSummaryContent}>Creative Aptitude</li>
                <li className={styles.winergeSummaryContent}>Mathematical Aptitude</li>
                <li className={styles.winergeSummaryContent}>Reading and Comprehension Power</li>
                <li className={styles.winergeSummaryContent}>Size and Cross Patterning</li>
                <li className={styles.winergeSummaryContent}>Tracking and Directional Strength</li>
            </ul>
        </div>

        {/* <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"12vh" }}>
                    <h4> <span style={{color:"#FBB03B"}}> In Smart-Machine Era,</span> <span style={{color:"white"}}>ability to</span> </h4>
                    </div> */}
                                        
                </Col>

                <Col lg={6} className="order-lg-1 order-1" style= {{paddingTop:"1vh"}}>
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>

            </Row>
        </Container>
    </div>
    );
}

export default CognitiveGrowth;
