import React from "react";
import {Outlet} from 'react-router-dom';

import Header from "./components/Header/header";


const RootOutlet = ()=>{
    return(
        <>
        <Header/>
        <Outlet/>
        
        </>
    );
}

export default RootOutlet;
