import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './SuccessSystem.module.css';
import arrow from '../../Assets/4x/Arrow.png'
import SuccessSystemDiagram from '../../Assets/4x/SuccessSystem@4x-8.png'

import VerticalSlideGallery from "../VerticalSlideGallery/VerticalSlideGallery";

const SuccessSystem = () =>{
    return(
        <>
         <div className={styles.body}>
       

       <Container fluid  >
           <Row>
               {/* <Col lg={4} style={{paddingLeft:"3vw", marginTop:"15px"}}> */}
               <Col lg={5} >
               <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                   {/* <img src={SuccessSystemDiagram} alt="Winerge Phone Summary" className={styles.phoneSummary}/> */}
                   <VerticalSlideGallery/>
                   </div>
               </Col>
               {/* <Col lg={1}>
               </Col> */}
               <Col lg={7} style={{paddingLeft:"3vw", marginTop:"15px", paddingTop:"8vh"}}>
                   <div style={{ display:"flex", justifyContent:"flex-start", }}>
                   <h2 className={styles.winergeSummaryHeadline}>WinGS <span style={{color:"#FBB03B"}}>- The Adaptive Growth System</span></h2>
                   </div>
                   
                   <Row>
                       <Col lg={1}>
                       </Col>
                       <Col lg={10}>
                       {/* <div className={styles.summaryDivStyle}>
                           <div>
                           <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} />Development of critical Growth Parameters</h4>
                           <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} />Personal Success Multiplier who also looks into School Remarks & Parents Remarks</h4>
                           <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} />Parents worries turned into definite assurance</h4>
                           </div>
                   </div> */}
                   {/* <div>
    <ul className={styles.winergeSummaryList}>
        <li className={styles.winergeSummaryContent}>
            <img src={arrow} alt="Arrow" className={styles.arrowStyle} />
            Development of critical Growth Parameters
        </li>
        <li className={styles.winergeSummaryContent}>
            <img src={arrow} alt="Arrow" className={styles.arrowStyle} />
            Personal Success Multiplier who also looks into School Remarks & Parents Remarks
        </li>
        <li className={styles.winergeSummaryContent}>
            <img src={arrow} alt="Arrow" className={styles.arrowStyle} />
            Parents worries turned into definite assurance
        </li>
    </ul>
</div> */}

        <div>
            <ul>
                <li className={styles.winergeSummaryContent} style={{paddingTop:"3vh"}}>Focus on Growth Parameters</li>
                <li className={styles.winergeSummaryContent}>Dedicated Success Multiplier to look into Teacher's & Parent Remarks</li>
                <li className={styles.winergeSummaryContent}>Parent worries turned into definite assurance</li>
                <li className={styles.winergeSummaryContent}>Interactive Learning & Support through engaging lessons, gamified challenges</li>
            </ul>
        </div>
                       </Col>
                   </Row>
                   
               </Col>
           </Row>
       </Container>
   </div>
        </>
    );
}

export default SuccessSystem;