import React, {useState, useEffect} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Typewriter from 'react-ts-typewriter'

import styles from './openingContent.module.css'
import ECGSystemCircle from '../../Assets/ECGSystemCircle.gif'
import scrollDownLogo from '../../Assets/4x/Scroll Down.png'

const OpeningContent = () =>{

    const [hideCursor, setHideCursor] = useState(true);
    const [showSecondLine, setShowSecondLine] = useState(false);
    const [showParagraph, setShowParagraph] = useState(false);

    return (
        <>
        <div className={styles.body}>
            <Container fluid  style= {{paddingTop:"8vh"}}>
            <Row >
                
                <Col style={{paddingLeft:"3vw", marginTop:"15vh", paddingRight:"5vw"}}>
                    <Row>
                        <Col style = {{color:"white", fontWeight:"600", fontFamily:"monospace"}} className={styles.contentFontSizeForWinGS}>
                        {/* Excel Today & */} 
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <span style={{color:" #FBB03B"}}>WinGS</span>
                        </div>
                         
                         {/* <Typewriter text='Enjoy Peace of Mind' cursor={hideCursor} onFinished={() => {setHideCursor(false); setShowSecondLine(true)}}/> */}
                         {/* <h1>WinGS</h1> */}
                            </Col>
                            
                        
                    </Row>
                    <Row>
                    <Col className={styles.contentFontSizeForSubHeading}>
                        {/* Excel Today & */} 
                        <div style={{display:"flex", justifyContent:"center", textAlign:"center"}} className={styles.managingSubHeading}>
                       {/* <h2><span style={{color:" #FBB03B", fontFamily:"monospace"}}>Outsource your child growth</span></h2>  */}
                       <Typewriter text='Guided Growth for Success in Smart-Machine Era' cursor={hideCursor} onFinished={() => {setHideCursor(false); setShowSecondLine(true)}}/>

                        </div>
                         
                         {/* <h1>WinGS</h1> */}
                            </Col>
                    </Row>
                    <Row>
                        {/* {showSecondLine && (<Col style = {{color:"white", fontWeight:"700", fontFamily:"sans-serif"}} className={styles.contentFontSize}>
                    
                        <Typewriter text='as you watch your child Excel and Thrive, guided by our Adaptive Growth System. (AGS)' onFinished={()=>{setShowParagraph(true)}}/>
                            </Col>)} */}
                        
                        {showSecondLine && (<Col style = {{color:"white", fontWeight:"700", fontFamily:"sans-serif"}} className={styles.contentFontSize}>
                            <div style={{display:"flex", justifyContent:"center", textAlign:"center"}} >
                            <Typewriter text='Welcome to the era of Adaptive Growth System.' onFinished={()=>{setShowParagraph(true)}}/>
                            </div>
                        {/* <Typewriter text='Welcome to the era of Adaptive Growth System.' onFinished={()=>{setShowParagraph(true)}}/> */}
                            </Col>)}
                        
                    </Row>

                    {/* <Row className={styles.marginParagraph}>
                    <Row >
                        {showParagraph && (
                            <Col className={styles.paragraph}>
                            Get the receipe you need for your success
                            </Col>
                            
                        )}
                    </Row>
                    <Row>
                        {showParagraph && (
                            <Col className={styles.paragraph}>
                            Get the Emotional & Cognitive Growth System
                            </Col>
                            
                        )}
                    </Row>
                    </Row> */}
                    
                    
                    {/* <Row style={{marginTop:"3vh", marginLeft:"auto"}}>
                        {showParagraph && (
                        <div style={{display:"block"}}>
                            <Button onClick={() => { window.location.href='#form'; }} className={styles.buttonStyling}>Get the ECG System Now</Button>
                            </div>
                            
                        )}
                    </Row> */}
                    
                </Col>
                {/* <Col lg={3} className="pb-0 mb-0">
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', paddingLeft:"2vw" }}>
                    <img src={ECGSystemCircle} alt="GIF Explaining ECG System" className={styles.ecgSystemSize}/>
                    </div>
                    
                </Col> */}
            </Row>
            </Container>

            {showParagraph && (<div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                <img src={scrollDownLogo} alt="Scroll Down Logo" className={styles.scrollDownLogo}/>
            </div>)}
        </div>
        <div>
        </div>
        </>
    );
}

export default OpeningContent;