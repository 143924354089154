import React from "react";


import styles from './OurMission.module.css'

const OurMission = () =>{
    return (
        <div className = {styles.body}>
            <h1 style={{textAlign:"center", paddingTop:"3vh", color:"#FBB03B"}} className={styles.textFont}>Our Core Drive</h1>
            <div style={{marginTop:"4vh", paddingBottom:"4vh"}} className={styles.MissionContentDiv}>
                <h4 style={{color:"white"}} className ={styles.ourMissionContent}>
                    {/* In a situation where 85% of jobs in 2035 don't exist now,  */}
                    {/* The "Industrial Revolution Model" based education system 
                    is not enough for job security, let alone success. 
                    The only way to command the "Smart Machine Era" is also to 
                    have an excellent Emotional & Cognitive Growth Score (ECG Score).
                    While parents want best for their child, and give their 100% on Nurturing, they don't have enough time
                    to be constanly updated with the new needs, trends of the era. */}
                    {/* In a rapidly changing Smart-Machine era where traditional education models fall short, 
                    we're revolutionizing child life with our proprietary Adaptive Growth System. 
                    Prioritizing meaningful growth over superficial metrics, 
                    we offer parents Adaptive Growth System(AGS).   
                    Our goal? Ensure every child thrives & wins in the 'Smart Machine Era', 
                    backed by a robust WinGS. */}
                    Traditional education isn't enough to prepare a children for a world increasingly shaped by AI and automation. That's why we created WinGS, our revolutionary Adaptive Growth System(AGS)
                    </h4>
                    <h4 style={{color:"whitesmoke"}} className ={styles.ourMissionContent}>
                        We go beyond standardized tests and outdated metrics. WinGS fosters the core skills and characteristics that influence long term achievement.
                    </h4>
                    <h4 style={{color:"white",  marginTop:"2vh", marginBottom:"2vh"}} className ={styles.ourMissionContent}>At winerge, our moto guides us:, <span style={{color:"#FBB03B"}}> "Excel Present, Command Smart Machine Era". </span> </h4>
                    <h4 style={{color:"whitesmoke"}} className ={styles.ourMissionContent}>We're committed to giving every child the tools to unlock their greatest potential</h4>
                    <div style={{display:"flex", justifyContent:"center", textAlign:"center", marginTop:"10vh"}}>
                    <h2><span style={{color:"#FBB03B", textAlign:"center", fontWeight:"bold"}}>How WinGS works?</span></h2>
                    </div>
                    
            </div>

            
        </div>
    );
}

export default OurMission;