import React from "react";
import ECGScore from "../components/ECGScore/ECGScore";
import Footer from "../components/Footer/Footer";

const ECGScorePage = ()=>{
  return(
    <>
    <ECGScore/>
    <Footer/>
    </>
  );
}

export default ECGScorePage;