import React, {useEffect} from "react";
import styles from './EcgSystemBlog.module.css'

import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


import Step1 from '../../../Assets/Step-1.png'
import Step2 from '../../../Assets/Step-2.png'
import Step3 from '../../../Assets/Step-3.png'

import Step1Component from '../../../Assets/Step1Component.png'
import Step2Component from '../../../Assets/Step2Component.png'

import GradeCentricism from '../../../Assets/4x/GradeCentricism.png'
import ParentDrivenNurturing from '../../../Assets/4x/ParentDrivenNurturing.png'
import ECGSystemProcess from '../../../Assets/4x/ECG-System-Process.png'

const ECGSystemBlog = () => {

    const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView();
        } else {
          // If the element is not available yet, wait for a moment and try again
          setTimeout(scrollToElement, 100);
        }
      }
    };
    scrollToElement();
  }, [location]);

    return(
        <>
        <Container style={{marginTop:"5vh"}} id="ECGSystemId">
            <Row>
                <div style={{display:"flex", justifyContent:"center", marginBottom:"3vh", }}>
                <h1 style={{fontWeight:"bold", color:"#1B1464", textAlign:"center"}}>The Three Pillars of WinGS</h1>
                </div>
                
                <Col lg={4} >
                <img src={Step1} alt="The ECG System Division" style={{width:"100%", marginBottom:"2vh"}} />
                </Col>
                <Col lg={4} >
                <img src={Step2} alt="The ECG System Division" style={{width:"100%",marginBottom:"2vh"}}/>
                </Col>
                <Col lg={4} >
                <img src={Step3} alt="The ECG System Division" style={{width:"100%",marginBottom:"2vh"}}/>
                </Col>
            </Row>
            
        </Container>
        
        <Container style={{ marginTop:"5vh" }}>
        <Row>
                <div style={{ marginBottom:"2vh" }}>
                <h2 style={{fontWeight:"bold", color:"#1B1464"}}>Pillar 1: The GQI (Growth Quotient Index)</h2>
                </div>
                <Col lg={2}>
                </Col>
                <Col>
                <h4 style={{fontWeight:"bold"}} >Reader, Remember?</h4>
                <h5 style={{fontWeight:"light"}}>
                Those days when report cards were all about A’s, B’s, and C’s? They 
                gave a sense of how our child was doing academically but left us wondering about the bigger picture.                  
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                 What about future's success? cognitive growth? creativity? resilience? improvement area?
                 </h5>
                 <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >What is GQI?</h4>
                <h5 style={{fontWeight:"light"}}>
                The GQI stands for <b>Growth Quotient Index.</b> Think of it as a health check-up,
                 but for your child’s fundamental growth parameters. It's not just about academic knowledge; it's about success skills!              
                    </h5>
                 <h4 style={{fontWeight:"bold" , marginTop:"2vh"}} >Why you should care?</h4>
                <h5 style={{fontWeight:"light"}}>
                In today's fast-paced Smart-Machine Era, success isn't just about academic brilliance. 
                It's about adaptability, emotional strength, cognitively strong, and more. 
                The GQI captures all these aspects, 
                giving you a comprehensive view of your child's preparedness for the future.              
                    </h5>
                {/* <h5 style={{fontWeight:"light"}}>
                See it requires two important component, Emotional Capability and Cognitive Capability to succeed. 
                In simpler words, Heart and Brain should collectively come together to dominate and succeed.
                    </h5> */}
                {/* <h5 style={{fontWeight:"light"}}>
                Emotional capability refers to mental grit, becoming distraction resistant,
                 able to tackle anxiety, pressure, Procrastination, etc. 
                 Subscribe to the newsletter to receive a more detailed post on Emotional Capability.
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Cognitive capability refers to Information Analysis & Judgement Formation,
                 Creative Aptitude, Memory Engineering, etc. 
                 Subscribe to the newsletter to receive a more detailed post on Cognitive Capability.
                    </h5> */}
                <h5 style={{fontWeight:"light"}}>
                If a child has great GQI, chances are he/she will excel in life, 
                whether it’s school life, career or Entrepreneurship. 
                Chances are, if a child is scoring average or below average marks, 
                he lacks somewhere in Growth Parameters.              
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                AGS focuses exactly on Growth Parameters of the child. 
                It’s known as “Smart Era Success System” where focus is on building blocks of child, 
                the roots of the child and not on outcomes. It is based on up-to date science 
                and research. The best part about the ECG System is you also have your personal 
                Success & Skill Multiplier, who personally tracks your growth and helps you grow.            
                    </h5>
                 <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >How does it work?</h4>
                <h5 style={{fontWeight:"light"}}>
                Your child's GQI is relative to their age group, ensuring a fair comparison.
                 High scores in certain areas indicate strengths, while lower scores pinpoint where 
                 they lack. And the best part? It's actionable. Based on the score,
                 the success Multiplier & WinGS works with the child ensuring proper nurturing.              
                    </h5>
                 <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >Future-proofing the child</h4>
                <h5 style={{fontWeight:"light"}}>
                Gone are the days of  <b>"Grade centricism"</b> or <b>"Doing It Myself"</b> style of Parent-Driven Growth. 
                With the AGS, we're embracing a future where every child gets 
                the Success they deserve. It's time to go beyond 
                traditional grades and truly understand our children's potential.              
                    </h5>
                 <h4 id="WinergeGrowthParams" style={{fontWeight:"bold", marginTop:"2vh"}} >What are Growth Parameters?</h4>
                <h5 style={{fontWeight:"light"}}>
                Growth Parameters are broad parameters, which are used to measure the growth of Individual. There are 5 main growth parameters:              
                    </h5>
                    <div><h5 style={{fontWeight:"light"}}>1. Physical</h5></div>
                    <div><h5 style={{fontWeight:"light"}}>2. Socio-Emotional</h5></div>
                    <div><h5 style={{fontWeight:"light"}}>3. Sensory</h5></div>
                    <div><h5 style={{fontWeight:"light"}}>4. Cognitive</h5></div>
                    <div><h5 style={{fontWeight:"light"}}>5. Communication</h5></div>
                    <h5>Each growth parameter has it's own Growth Quotient, which contributes to GQI</h5>
                 <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >What is Grade Centricism?</h4>
                <h5 style={{fontWeight:"light"}}>
                In the AI & Smart Machine Era, where 85% of Jobs of 2035 don’t even exist yet, further McKinsey has predicted that one-third 
                of the entire world’s workforce will be displaced by automation by 2030. Do you think your child is best prepared for it by just relying on Acads?</h5>
                
                <h5 style={{fontWeight:"light"}}>
                Is your child ready for a Dynamic World? where things are gonna change even more rapidly(The law of Accelerating Returns)
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Grade Centricism refers when the sole focus and measurement criteria for the child is "Grade" in the School. 
                Traditionally, it was really valuable, but in Smart Machine Era, where changes are so fast, Skills and Capabilities hold
                more value than Marks. 
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                So Grade Centricism is the mindset, where the vision is very short-sighted. And eventually the struggle of catching up begins,
                only leading to a undesirable life.
                    </h5>
                    <div style={{textAlign:"center"}}>
                    <img src={GradeCentricism} alt="The Grade Centricism Depiction" className={styles.imageDisplay} />
                    </div>
                    
                    <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >What is "Doing it Myself" Style in Parent-Driven Growth?</h4>
                <h5 style={{fontWeight:"light"}}>
                Parent-Driven Growth is when parent tries to take up all the responsibility of Growth, and this style is known as "Doing it Myself" or DIM Style. 
                We understand it's driven out of Love and Protection, but it's like trying to be Sun, Water, Soil, Rain for the seed all at once.  
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Moreover, it leads to a lot of overwhelmness, a state of exhaustion. All this leads to undesirable results.
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                We think it needs to be stopped, we should onboard proper system for our child, for their future, for their success.
                    </h5>
                    
                    <div style={{textAlign:"center"}}>
                    <img src={ParentDrivenNurturing} alt="The Parent Driven Growth Depiction" className={styles.imageDisplay} />
                    </div>
                </Col>
                <Col lg={2}>
                </Col>
                <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>
            </Row>
        </Container>
        <Container style={{ marginTop:"5vh" }}>
        <Row>
                <div style={{ marginBottom:"2vh" }}>
                <h2 style={{fontWeight:"bold", color:"#1B1464"}}>Pillar 2: Success & Exam Multiplier</h2>
                </div>
                <Col lg={2}>
                </Col>
                <Col>
                <h5 style={{fontWeight:"light"}}>
                Elon Musk, Steve Jobs, Jeff Bezoz, Mukesh Ambani, Every Achiever has it's council, Your child
                deserves one too!             
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Multipliers/the personal trainers, are experts in the field, who works one-to-one with the children, and help them figure out their path.           
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                    It's the most game changing thing about WinGS - Getting coaches, personalized for your child.          
                    </h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >Success Multiplier</h4>
                <h5 style={{fontWeight:"light"}}>
                Success Multiplier is a person that focuses and works on Growth Parameters. Success Multiplier assures you
                are becoming ready for the present and future.              
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Also, all the personal problems are now going to be taken care of!           
                    </h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >Skill Multiplier</h4>
                <h5 style={{fontWeight:"light"}}>
                Why stop only on Improving fundamentals for Success, when you can compliment it with much more!         
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Have skills as Goal? Skill Multiplier will help you lay a roadmap and help you cross every skill development hurdle
                you are facing.           
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                Worry not, Having Skill Multiplier is upto you. It's an ADD-ON.        
                    </h5>
                </Col>
                <Col lg={2}>
                </Col>
                
                <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>

            </Row>
        </Container>
        <Container style={{ marginTop:"5vh" }}>
        <Row>
                <div style={{ marginBottom:"2vh" }} id="GrowthCycleId">
                <h2 style={{fontWeight:"bold", color:"#1B1464"}}>Pillar 3: The Growth Cycle</h2>
                </div>
                <Col lg={2}>
                </Col>
                <Col>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >It's a continuous process</h4>
                <h5 style={{fontWeight:"light"}}>
                    It Begins with GQI, A child gets his GQI.         
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                    Success Multiplier analyze & evaluates the GQI, and builds up the Success Plan for the child.      
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                    Child attends the workshops, assignments, videos, 30 mins a day, and weekly meets the Success Multiplier.      
                    </h5>
                <h5 style={{fontWeight:"light"}}>
                    Success Multiplier will keep on evaluating and changing the roadmap as the progress Improves.
                    </h5>
                    <div style={{textAlign:"center"}}>
                    <img src={ECGSystemProcess} alt="The ECG System process Depiction" className={styles.imageDisplay} />
                    </div>
                </Col>
                <Col lg={2}>
                </Col>
                
            </Row>
        </Container>
        <Container style={{marginTop:"5vh"}}>
            <Row>
                <div style={{display:"flex", justifyContent:"center", marginBottom:"3vh", }}>
                <h1 style={{fontWeight:"bold", color:"#1B1464", textAlign:"center"}}>The Two Imp Growth Parameters</h1>
                </div>
                
                <Col lg={6} >
                <img src={Step1Component} alt="The WinGS Division" style={{width:"80%", marginBottom:"2vh"}} />
                </Col>
                <Col lg={6} >
                <img src={Step2Component} alt="The WinGS Division" style={{width:"80%",marginBottom:"2vh"}}/>
                </Col>
            </Row>
            
        </Container>
        
        <Container style={{ marginTop:"5vh" }}>
        <Row>
                <div style={{ marginBottom:"2vh" }}>
                <h2 style={{fontWeight:"bold", color:"#1B1464"}}>Component 1: The Emotional Component</h2>
                </div>
                <Col lg={2}>
                </Col>
                <Col>
               
                <h5 style={{fontWeight:"light", textAlign:"center", color:"white", backgroundColor:"#1B1464", padding:"3vh", margin:"3vh"}}>
                "80%-90% of the competencies that differentiate top performers are in the domain of emotional intelligence"     
                <div>
                    <h5 style={{textAlign:"right", paddingTop:"2vh"}}>- Daniel Goleman</h5>
                    </div>    
                    </h5>
                   
                <h5 style={{fontWeight:"light"}}>
                In today's hyper-competitive world, there's a rush to make children
                cram with knowledge, to make them walking encyclopedias. 
                But is that enough? The answer is a resounding no. At Winerge, 
                we believe that the emotional quotient is as crucial, if not more so, 
                than the intelligence quotient. Here's why.
                     </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >The Foundation of Resilience:</h4>
                <h5 style={{fontWeight:"light"}}>
                Life isn't about the setbacks we face, but how we rise from them. 
                The emotional parameter of our WinGS instills a strong sense 
                of resilience in children. They learn the art of bouncing back, 
                transforming failures into stepping stones.         
                    </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Building Authentic Relationships:</h4>
                <h5 style={{fontWeight:"light"}}>
                Emotional intelligence is key to understanding, empathy, and connection. 
                By nurturing this, we're equipping children to build genuine relationships, 
                fostering collaboration, and understanding – skills vital in both personal
                 and professional spheres.         
                    </h5>
                    <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Distraction Resistant:</h4>
                <h5 style={{fontWeight:"light"}}>
                The one thing, which has definitely changed is the huge amount of distractions out there.
                You can't win if you can't resist distraction. Get the Distraction Defense System in place with you.         
                    </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >Cultivating Self-belief:</h4>
                <h5 style={{fontWeight:"light"}}>
                Confidence isn't about arrogance; it's about believing in oneself. 
                Our system ensures children develop a robust self-belief, 
                empowering them to face challenges head-on, undeterred by self-doubt.         
                    </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} >Managing Anxiety and Procrastination:</h4>
                <h5 style={{fontWeight:"light"}}>
                In an age of information overload, children often grapple with anxiety and 
                the urge to delay tasks. By honing their emotional skills, we teach them to 
                manage these feelings effectively, ensuring they remain focused and driven.        
                    </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > The Power of Discipline:</h4>
                <h5 style={{fontWeight:"light"}}>
                Discipline isn't about rigidity. It's about consistency and commitment.
                Through the emotional component of our system, children internalize the 
                value of compounded discipline, ensuring they remain steadfast in their pursuits.         
                    </h5>
                     <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > That's it?</h4>
                <h5 style={{fontWeight:"light"}}>
                We just started, the list is way too long. We are working on it, adding things up, improving things up.         
                    </h5>
                     <h4 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", marginTop:"4vh"}} > One must realise that, Winners are nothing but Emotionally & Cognitively Grown individuals.</h4>
                <h5 style={{fontWeight:"light", textAlign:"center", color:"white", backgroundColor:"#1B1464", padding:"3vh", margin:"3vh"}}>
                "I've missed more than 9000 shots in my career. I've lost almost 300 games. 26 times, i've been trusted to take the game winning shot and missed. I've failed over and over and over again in my life. And that is why I succeed"     
                <div>
                    <h5 style={{textAlign:"right", paddingTop:"2vh"}}>-Michael Jordan</h5>
                    </div>    
                    </h5>
                    <h4 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", marginTop:"4vh"}} > The question is Do you have capability to handle the situation: missing the winning shot?</h4>
                     
                </Col>
                <Col lg={2}>
                </Col>
                <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>
                
            </Row>
        </Container>
        <Container style={{ marginTop:"5vh" }}>
        <Row>
                <div style={{ marginBottom:"2vh" }}>
                <h2 style={{fontWeight:"bold", color:"#1B1464"}}>Component 2: The Cognitive Component</h2>
                </div>
                <Col lg={2}>
                </Col>
                <Col>
                <h5 style={{fontWeight:"light", textAlign:"center", color:"white", backgroundColor:"#1B1464", padding:"3vh", margin:"3vh"}}>
                "We have the Brain, that can change itself! all we need is the right way"   
                <div>
                    <h5 style={{textAlign:"right", paddingTop:"2vh"}}>- Neuroplasticity</h5>
                    </div>    
                    </h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Critical Information Analysis:</h4>
                <h5 style={{fontWeight:"light"}}> In the age of information, figuring out truths among fiction is critical. 
                Our system nurtures children to critically analyze data, helping them make informed decisions 
                in an increasingly complex world.</h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Judgement Formation:</h4>
                <h5 style={{fontWeight:"light"}}> Beyond just absorbing information, children learn to form 
                opinions and judgements. This fosters independent thinking, ensuring 
                they're not just passive consumers of knowledge but active contributors.</h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Enhancing Visual Memory:</h4>
                <h5 style={{fontWeight:"light"}}> A sharp memory isn't just about recalling facts for exams. 
                Our system strengthens visual memory, aiding children in everything from 
                remembering faces to deciphering patterns, essential 
                in fields like design and technology.</h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Boosting Creative Aptitude:</h4>
                <h5 style={{fontWeight:"light"}}> Innovation is the need of the hour. By honing creative aptitude, 
                we're preparing children to think outside the box, ensuring they're not just ready for the jobs
                 of today but are pioneering the roles of tomorrow.</h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Mathematical and Logical Prowess:</h4>
                <h5 style={{fontWeight:"light"}}> Math isn't just about numbers; it's the language of logic. 
                Our cognitive component ensures children develop a strong mathematical aptitude, 
                setting them up for success in varied fields, from finance to computer programming.</h5>
                <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > Directional Strength and Problem Solving:</h4>
                <h5 style={{fontWeight:"light"}}> Life is about navigating challenges. 
                By developing directional strength and problem-solving skills, 
                children are equipped to tackle obstacles head-on, whether it's
                 in a math puzzle or a real-world scenario</h5>
                 <h4 style={{fontWeight:"bold", marginTop:"2vh"}} > That's it?</h4>
                <h5 style={{fontWeight:"light"}}>
                We just started, the list is way too long. We are working on it, adding things up, improving things up.         
                    </h5>

                    <h4 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", marginTop:"4vh"}} >The Intelligence is not Limited, The question is Do you have any Coach with you?</h4>
                <h5 style={{fontWeight:"light", textAlign:"center", color:"white", backgroundColor:"#1B1464", padding:"3vh", margin:"3vh"}}>
                “Give me a dozen healthy infants, well-formed, and my own specified world to bring them up in and I’ll guarantee to take any one at random and train him to become any type of specialist I might select — doctor, lawyer, artist, merchant-chief and, yes, even beggar-man and thief, regardless of his talents, penchants, tendencies, abilities, vocations, and race of his ancestors.”     
                <div>
                    <h5 style={{textAlign:"right", paddingTop:"2vh"}}>- John Watson</h5>
                    </div>    
                    </h5>
                    <h4 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", marginTop:"4vh"}} > The question is do you want TO BE THE EXCEPTION?</h4>
                    
                </Col>
                <Col lg={2}>
                </Col>
                <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>
            </Row>
        </Container>

<Container style={{marginTop:"5vh"}}>
<Row>
    <div  id="WinergeId" style={{display:"flex", justifyContent:"center", marginBottom:"3vh", }}>
    <h1 style={{fontWeight:"bold", color:"#1B1464", textAlign:"center"}}>The Winerge</h1>
    </div>
    
    <Col lg={2}>
    </Col>
    <Col >
    <h5 style={{fontWeight:"light", textAlign:"center", color:"white", backgroundColor:"#1B1464", padding:"3vh", margin:"3vh"}}>
    "We Believe in Excellence and the right to be the Exception!"   
                <div>
                    <h5 style={{textAlign:"right", paddingTop:"2vh"}}>- WINERGE</h5>
                    </div>    
                    </h5>
            
            <h5 style={{fontWeight:"light"}}>At Winerge we believe that not living one's full potential is the biggest crime an individual can do. Getting stuck is fine, but remaining stucked is not.</h5>
            <h5 style={{fontWeight:"light"}}>We Believe in achieving more! and if you are reading this, We want to tell you that we understand you. Your want to win, your fear to remain average and die.</h5>
            <h5 style={{fontWeight:"light"}}>At winerge, we don't believe in LUCK, we believe in process. We don't believe in dreaming unless accompanied by Hardwork.</h5>

            <h5 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", margin:"3vh"}}>We are on a mission to change the World, one individual at a time!</h5>

            <h5 style={{fontWeight:"light"}}>We do so by</h5>
            
                <h5 style={{fontWeight:"light"}}>- Hiring the best possible team</h5 >
                <h5 style={{fontWeight:"light"}}>- Building the right actionables</h5 >
                <h5 style={{fontWeight:"light"}}>- Focusing on community led growth</h5 >
                <h5 style={{fontWeight:"light"}}>- Providing Success & Exam Multipliers</h5 >
            
            <h5 style={{fontWeight:"bold", textAlign:"center", color:"#1B1464", margin:"3vh"}}>If you are a Parent, Looking out this for your child</h5>
            <h5 style={{fontWeight:"light"}}>We want to tell you that:</h5>
         
                <h5 style={{fontWeight:"light"}}>- This is the Best thing you can invest for you child success</h5>
                <h5 style={{fontWeight:"light"}}>- You will receive continuous growth report of your child</h5>
                <h5 style={{fontWeight:"light"}}>- With our Parent-Child Module you will have more meaningful relation with your child</h5>
                <h5 style={{fontWeight:"light", marginBottom:"5vh"}}>- Your Child will be in safest hands, with Success Multipliers always present there</h5>
           

    </Col>
    <Col lg={2}>
    </Col>

    <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>
</Row>

</Container>
        
      
        </>
    )
}

export default ECGSystemBlog;