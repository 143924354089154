import React  from "react";
import styles from './mainForm.module.css';
import {Form, redirect, useNavigation} from 'react-router-dom';


const MainForm = () => {

    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';

    // Use ref


    return (
        <>
        <div id="form" className={styles.mainBody}>
            <div className={styles.container}>
                <div className={styles.text}>
                    Let's Talk
                </div>
                <Form method="post" action="#"  >
                    <div className={styles["form-row"]}>
                        <div className={styles["input-data"]}>
                            <input type="text" name="Name" required />
                            <div className={styles.underline}></div>
                            <label>Name*</label>
                        </div>
                        <div className={styles["input-data"]}>
                            <input type="text" name="EmailId" required />
                            <div className={styles.underline}></div>
                            <label>Email-Id*</label>
                        </div>
                    </div>
                    <div className={styles["form-row"]}>
                        <div className={styles["input-data"]}>
                            <input type="text" name= "PhoneNo" required />
                            <div className={styles.underline}></div>
                            <label>Phone No.*</label>
                        </div>
                        <div className={styles["input-data"]}>
                            <input type="text" name="ChildName" required />
                            <div className={styles.underline}></div>
                            <label>Child Name*</label>
                        </div>
                    </div>
                    <div className={styles["form-row"]}>
                        <div className={`${styles["input-data"]} ${styles.textarea}`}>
                            <textarea rows="8" cols="80" name="Message"></textarea>
                            <div className={styles.underline}></div>
                            <label>Write your message</label>
                            {/* <div className={`${styles["form-row"]} ${styles["submit-btn"]}`}>
                                <div className={styles["input-data"]}>
                                    <div className={styles.inner}></div>
                                    <input type="submit" value="submit" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={`${styles["form-row"]} ${styles["submit-btn"]}`}>
                                <div className={styles["input-data"]}>
                                    <div className={styles.inner}></div>
                                    <input type="submit" value={isSubmitting ? "Submitting.." :"Submit"} disabled={isSubmitting} />
                                </div>
                            </div>
                </Form>
            </div>
            </div>
        </>
    );
}

export default MainForm;


// Action Function

export async function action ({request, params}){

    const data = await request.formData();
    const eventData = {
        name : data.get('Name'),
     emailId : data.get('EmailId'),
     phoneNo : data.get('PhoneNo'),
     childName : data.get('ChildName'),
     message : data.get('Message'),

    }


 const response = await fetch('http://62.72.56.104:5000/submit-form', {
        method: 'POST',
        headers:{
            'Content-Type':'application/json',
        },
        body: JSON.stringify(eventData)
    });

    if(!response.ok){
        throw new Error('Could not save the details');
    }

    return redirect('/thankyou');

}