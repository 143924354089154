import React from "react";
import neverLikeThis from '../../Assets/videos/DsyFamily.mp4'

import styles from './NoToDIM.module.css'

const NoToDIM = () =>{

    return(
        <div className={styles.mainContainer}>
            {/* <div className={styles.overlay}></div>
            <video src={neverLikeThis} autoPlay loop muted/> */}
            
                    <div className={styles.contentBox} style ={{paddingTop:"5vh"}}>
                <h1 style={{fontWeight:"bold"}} className={styles.heading}>Say <span style={{color:"#FBB03B"}}>#NO TO "DOING IT MYSELF" APPROACH</span></h1>

                <h3 style={{paddingTop:"3vw"}} className={styles.content}>I know Schools end up not meeting your expectations, and you end doing everything up.</h3>
                <h3 style={{paddingTop:"3vw"}} className={styles.content}>Have you also felt "Why am I doing everything?"</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>It's a bad idea to be everyting, or do something based on Youtube, suggestions of friends, family.</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>It takes a team, a team of experts. I hope you want that for your child too?</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>From experimenting to #AGS, it's time for change</h3>

            </div>
              
            
        </div>
    );
}


export default NoToDIM;