import React from 'react';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './winergeSummary.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/OpeningGIF.png'

const WinergeSummary = () => {
  return (
    <div className={styles.body}>
       

        <Container fluid  style= {{paddingTop:"8vh"}}>
            
            <Row className={styles.HandlingGIF}>
                {/* <Col lg={4} style={{paddingLeft:"3vw", marginTop:"15px"}}>
                <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={winergeSummary} alt="Winerge Phone Summary" className={styles.phoneSummary}/>
                    </div>
                </Col> */}
                <Col lg={6} className="pb-0 mb-0">
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>
                
                {/* <Col lg={2}>
                </Col> */}
                <Col lg={6} style={{paddingLeft:"3vw", marginTop:"15px"}} className={styles.HandlingContent}>
                    <div style={{ display:"flex", justifyContent:"flex-start", }}>
                    <h2 className={styles.winergeSummaryHeadline}> <span> WinGS makes growing up more secure for every child, by focusing on Growth Parameters, with plans made just for them</span></h2>
                    </div>
                    
                    {/* <Row>
                        <Col lg={1}>
                        </Col>
                        <Col lg={10}>
                        <div className={styles.summaryDivStyle}>
                            <div>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Harnessing boundless growth: ECG Score at your service</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Parenthood, redefined: Evolve from solo efforts to systemic support</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Close the growth gap: holistic development with every check-in</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Learning reimagined: Organic growth over grade centricism</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Success Multiplier: Personal coach for Success</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Future-proofing: Excel Smart Machine Era</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>60 mins perday: That's all we demand</h4>
                            </div>
                    </div>
                        </Col>
                    </Row> */}
                    
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default WinergeSummary;
