import React from "react";
import neverLikeThis from '../../Assets/videos/RoboWorld.mp4'

import styles from './NoToGrade.module.css'

const NoToGrade = () =>{

    return(
        <div className={styles.mainContainer}>
            {/* <div className={styles.overlay}></div> */}
            {/* <video src={neverLikeThis} autoPlay loop muted/> */}
            
                    <div className={styles.contentBox} style ={{paddingTop:"5vh"}}>
                <h1 style={{fontWeight:"bold"}} className={styles.heading}>Say <span style={{color:"#FBB03B"}}>#NO TO GRADE CENTRICISM</span></h1>

                <h3 style={{paddingTop:"3vw"}} className={styles.content}>Do you really want to bet your child's future driven on Academic Marks?</h3>
                <h3 style={{paddingTop:"3vw"}} className={styles.content}>Spending 25% of life on something which barely matters?</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>In the era of Smart-Machine, driven by AI, the things will be different, Here are some points:</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>1. Big-5 predicts that 85% ofJobs of 2035 don’t even yet.</h3>
                <h3 style={{paddingTop:"2vw"}} className={styles.content}>2. McKinsey has predicted that 1/3rd of the entire world’s workforce will be displaced by automation by 2030.</h3>

            </div>
        </div>
    );
}


export default NoToGrade;