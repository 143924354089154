import React from 'react';
import styles from './VerticalSlideGallery.module.css';

import p1 from '../../Assets/Scroll/p1.jpg'
import p2 from '../../Assets/Scroll/p2.jpg'
import p3 from '../../Assets/Scroll/p3.jpg'
import p4 from '../../Assets/Scroll/p4.jpg'
import p5 from '../../Assets/Scroll/p5.jpg'
import p6 from '../../Assets/Scroll/p6.jpg'
import p7 from '../../Assets/Scroll/p7.jpg'
import p8 from '../../Assets/Scroll/p8.jpg'
import p9 from '../../Assets/Scroll/p9.jpg'
import p11 from '../../Assets/Scroll/p11.jpeg'
import p12 from '../../Assets/Scroll/p12.jpg'
import p13 from '../../Assets/Scroll/p13.jpg'
import p14 from '../../Assets/Scroll/p14.jpg'
import p15 from '../../Assets/Scroll/p15.jpg'

const VerticalSlideGallery = () => {
    return (
        <div className={styles.galleryContainer}>
            <div className={styles.gallery}>
                {/* Gallery line 1 */}
                <div className={styles["gallery_line"]}>
                <img alt="Winerge ECG System" src={p8}/>
                <img alt="Winerge ECG System" src={p9}/>
                <img alt="Winerge ECG System" src={p13}/>
                <img alt="Winerge ECG System" src={p14}/>
                <img alt="Winerge ECG System" src={p9}/>
                <img alt="Winerge ECG System" src={p1}/>
                
                <img alt="Winerge ECG System" src={p8}/>
                <img alt="Winerge ECG System" src={p9}/>
                <img alt="Winerge ECG System" src={p13}/>
                <img alt="Winerge ECG System"src={p14}/>
                <img alt="Winerge ECG System" src={p9}/>
                <img alt="Winerge ECG System" src={p1}/>
                </div>

                <div className={styles["gallery_line"]}>
                <img alt="Winerge ECG System" src={p4}/>
                <img alt="Winerge ECG System" src={p2}/>
                <img alt="Winerge ECG System" src={p1}/>
                <img alt="Winerge ECG System" src={p6}/>
                <img alt="Winerge ECG System" src={p7}/>
                <img alt="Winerge ECG System" src={p9}/>
                
                <img alt="Winerge ECG System" src={p4}/>
                <img alt="Winerge ECG System" src={p2}/>
                <img alt="Winerge ECG System" src={p1}/>
                <img alt="Winerge ECG System" src={p6}/>
                <img alt="Winerge ECG System" src={p7}/>
                <img alt="Winerge ECG System" src={p9}/>
                </div>

                {/* Gallery line 2 */}
                {/* <div className={styles["gallery_line"]}>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p3}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p5}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p11}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p12}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p15}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p5}/>
                
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p3}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p5}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p11}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p12}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p15}/>
                <img alt="Winerge ECG System" style={{height:"minHeight"}} src={p5}/>
                </div> */}
                {/* Gallery line 3 */}
                
            </div>
            <div className={styles.Me}>THE ECG SYSTEM</div>
        </div>
    );
}

export default VerticalSlideGallery;
