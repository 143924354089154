import React from "react";
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import Home from "./pages/home";
// import MainForm from "./components/Forms/mainForm/mainForm";
import ECGSystem from "./pages/ecgSystem";
import RootOutlet from "./RootOutlet";
import ECGScorePage from "./pages/ecgScorePage";

import {action as mainFormAction} from './components/Forms/mainForm/mainForm'

import SubstackFeed from "./components/Substack/SubstackFeed";
import NewsLetterPage from "./pages/NewsLetter";
import ThankYouPage from "./components/Thankyou/Thankyou";

import ScorePage from "./components/ScorePage/ScorePage";
  
  const router = createBrowserRouter([
    {path:'/',
     element: <RootOutlet/>,
     children: [
        {path:'/', element:<Home/>, action: mainFormAction},
        {path:'/ecgsystem', element:<ECGSystem/>},
        {path:'/blogs', element:<SubstackFeed/>},
        {path:'/ecgscore', element:<ECGScorePage/>},
        {path:'/newsletter', element:<NewsLetterPage/>},
        {path:'/joinus', element:<h1>Join US</h1>},
        {path:'/thankyou', element:<ThankYouPage/>},
        {path:'/myscore', element:<ScorePage/>},
     ]
    }
  ]);


const App = ()=>{
    return(
        <RouterProvider router={router}/>
    );
}

export default App;