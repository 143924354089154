import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NewsLetterPage = () => {
  
    useEffect(() => {
    
  
      // Load the script dynamically
      const script = document.createElement('script');
      script.src = "https://substackapi.com/embeds/feed.js";
      script.async = true;
      document.body.appendChild(script);
  
      // Optional: You can remove the script when the component is unmounted
      return () => {
        document.body.removeChild(script);
      };
  
    }, []); // Empty dependency array ensures the effect runs only once
  
    return (
      <>     
          <Container>
              <Row>
                  <Col lg={2}></Col>
                  <Col lg={8}>
            <h3 style={{textAlign:"center", fontWeight:"bold", marginTop:"3vh"}}>Subscribe to our NewsLetter!</h3>
           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4vh' }}>
        <iframe 
          src="https://winerge.substack.com/embed" 
          width="auto" 
          height="auto" 
          style={{ border: '1px solid #EEE', background: 'white' }} 
          frameBorder="0" 
          scrolling="no"
        ></iframe>
      </div>
                  </Col>
                  <Col lg={2}></Col>
              </Row>
  
          </Container>
           
      </>
     
    );
  };
  
  export default NewsLetterPage;
  