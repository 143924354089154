import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './WhoIsThisFor.module.css'

const WhoIsThisFor = () =>{

    return(   

        <div className={styles.body}>
        
        <h2 style={{textAlign:"center", paddingTop:"2vh", paddingBottom:"5vh", color:"black", fontWeight:"bold"}} className={styles.textFont}>WinGS might be a MUST-HAVE if:</h2>

        <Container  >
            <Row className="align-items-center">
                <Col lg={4}>
                    <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center"}} className={`${styles.shadow} ${styles.border}`}>
                    <h5>You deeply question if traditional school sufficiently prepares children for the unique challenges of an uncertain future.</h5>
                    </div>
                </Col>
                <Col lg={4}>
                <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center"}} className={`${styles.shadow} ${styles.border}`}>
                    <h5>You worry your child isn't developing essential adaptability, inteliigence, life-skills for future, and may left behind.</h5>
                    </div>
                </Col>
                <Col lg={4}>
                <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center", }} className={`${styles.shadow} ${styles.border}`}>
                    <h5>You are willing to invest in a system that prioritizes future-ready skills development, even if it comes at a cost.</h5>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
    );
}

export default WhoIsThisFor;