import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './doingItMyself.module.css';
import arrow from '../../Assets/4x/Arrow.png';
import overwhelmedParents from '../../Assets/4x/DoingItMyself@4x-8.png'

import GQI from '../../Assets/videos/GQI.gif'

const DoingItMyself = () =>{
    return (
        <div className = {styles.body}>
            <Container>
                <Row className={styles.contentBoxAlign}>
                    <Col lg={6} className="order-lg-1 order-3">
                    <div>
                    <h2 className={styles.winergeSummaryHeadline}><span style={{color:"white"}}>The progress is driven by their aspirations, and our Growth Quotient Index(GQI).</span></h2>
                    </div>
                    {/* <Row>
                   
                        <Col lg={10}>
                        <div className={styles.summaryDivStyle}>
                            <div>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>It is a characterisitc of Parent-Driven Growth</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Relying on your instincts or suggestion of others</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Becoming stressed, over-controlling or overwhelmed</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Figuring out the new-Gen needs and requirements</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>No idea of How to make child future-proof</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Can't spend whole day researching, planning and implementing growth strategies</h4>
                            <h4 className={styles.winergeSummaryContent}><img src={arrow} className= {styles.arrowStyle} alt='Arrow for labelling'/>Understands that Success requires more than Academic Marks</h4>
                            </div>
                    </div>
                        </Col>
                        
                    </Row> */}
                    </Col>
                    <Col lg={6} className="order-lg-1 order-1" >
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                      <img src={GQI} alt="Growth Quotient Index Image" className={styles.overwhelmedParents}/>        
                    </div>
                    </Col>
                </Row>

                <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', textAlign:"center", marginTop:"10vh"}}>
                    <h3 style={{color:"white"}}> <span style={{color:"#FBB03B"}} >Growth Quotient Index </span> is the measure of all the <span style={{color:"#FBB03B"}}>Growth Parameters</span> of the Individual</h3>
                    </div>
            </Container>
        </div>
    );
}

export default DoingItMyself;
