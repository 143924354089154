import React from "react";
import ReactPlayer from 'react-player/lazy'

const WinergeLaunch = () =>{
    return (
        <>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <ReactPlayer style={{marginTop:"15vh", marginBottom:"15vh"}} controls='true' url='https://youtu.be/XAOecJ6Aas0'/>
        </div>

        
        </>
    );

}

export default WinergeLaunch;