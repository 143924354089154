import React from "react";

import OpeningContent from "../components/Opening_Home/openingContent";
import WinergeSummary from "../components/WinergeSummary/winergeSummary";

import DoingItMyself from "../components/doingItMyself/doingItMyself";
import OurMission from "../components/OurMission/OurMission";
import SuccessSystem from "../components/SuccessSystem/SuccessSystem";
import CognitiveGrowth from "../components/CognitiveGrowth/CognitiveGrowth";
import EmotionalGrowth from "../components/EmotionalGrowth/EmotionalGrowth";
import FounderSpeak from "../components/FounderSpeaks/FounderSpeak";
import Reviews from "../components/Reviews/Reviews";
import LowAcadScore from "../components/LowAcadScore/LowAcadScore";
import MainForm from "../components/Forms/mainForm/mainForm";
import Footer from "../components/Footer/Footer";

import TestingServer from "../components/Forms/testingServer";
import PricingTable from "../components/PricingTable/PricingTable";
import WinergeForYou from "../components/WinergeForYou/WinergeForYou";
import WinergeLaunch from "../components/ReactPlayer/WinergeLaunch/WinergeLaunch";
import AchieveAnything from "../components/AchieveAnything/AchieveAnything";

import NeverLikeThis from "../components/NeverLikeThis/NeverLikeThis";
import GrowthQuotientIndex from "../components/GrowthQuotientIndex/GrowthQuotientIndex";
import GrowthParameter from "../components/GrowthParameter/GrowthParameter";
import TimeToExcel from "../components/TimeToExcel/TimeToExcel";
import NoToGrade from "../components/NoToGradeCentricism/NoToGrade";
import NoToDIM from "../components/NoToDIM/NoToDIM";

import OverallGrowthPlan from "../components/OverallGrowth/OverallGrowth";

import WhoIsThisFor from "../components/WhoIsThisFor/WhoIsThisFor";
import WhoThisMightBeFor from "../components/WhoThisMightBeFor/WhoThisMightBeFor";


const Home = () =>{

    return(
        <>
        
        <OpeningContent></OpeningContent>
        <WinergeSummary></WinergeSummary>
        <DoingItMyself></DoingItMyself>
        <AchieveAnything></AchieveAnything>
        <NeverLikeThis></NeverLikeThis>
        <WinergeForYou></WinergeForYou>
        <SuccessSystem></SuccessSystem>
        <WinergeLaunch></WinergeLaunch>
        <OurMission></OurMission>
        <GrowthQuotientIndex></GrowthQuotientIndex>
        <GrowthParameter></GrowthParameter>
        <CognitiveGrowth></CognitiveGrowth>
        <EmotionalGrowth></EmotionalGrowth>
        <OverallGrowthPlan></OverallGrowthPlan>
        <TimeToExcel></TimeToExcel>
        <WhoIsThisFor></WhoIsThisFor>
        
        <Reviews></Reviews>
        <WhoThisMightBeFor></WhoThisMightBeFor>
        <NoToGrade></NoToGrade>
        <NoToDIM></NoToDIM>
        {/* <LowAcadScore></LowAcadScore> */}
        <PricingTable></PricingTable>
        <MainForm></MainForm>
        <TestingServer></TestingServer>
        {/* <FounderSpeak></FounderSpeak> */}
        <Footer/>
        </>
    );
}

export default Home;