import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './EmotionalGrowth.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/EmotionalParams.png'

const EmotionalGrowth = () =>{
    return (
        <div className={styles.body}>
       

        <Container fluid >
            
            <Row >
            <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"5vh", paddingLeft:"4vw" }}>
                    <h2 className={styles.winergeSummaryHeadline}> <span style={{color:"#FBB03B"}}>Socio-Emotional Capability</span></h2>
                    </div>
            <Col lg={6} className="pb-0 mb-0" style= {{paddingTop:"5vh"}}>
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>
                
                <Col lg={6} style={{ paddingLeft:"4vw"}} className={styles.HandlingContent}>
                    

                    <div>
            <ul>
                <li className={styles.winergeSummaryContent} style ={{ paddingTop:"2.5vh"}} >Achiever Obsession</li>
                <li className={styles.winergeSummaryContent}>Being Responsive & not Reactive</li>
                <li className={styles.winergeSummaryContent}>Grit and Perserverance</li>
                <li className={styles.winergeSummaryContent}>Bounce Back Capabilities</li>
                <li className={styles.winergeSummaryContent}>Compounded Discipline</li>
                <li className={styles.winergeSummaryContent}>Distraction & Addiction Resistant</li>
                <li className={styles.winergeSummaryContent}>Self Confidence & Belief System</li>
                <li className={styles.winergeSummaryContent}>Anxiety & Procrastination Diminished</li>
            </ul>
        </div>

        {/* <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"12vh" }}>
                    <h4> <span style={{color:"#FBB03B"}}> In Smart-Machine Era,</span> <span style={{color:"white"}}>ability to</span> </h4>
                    </div> */}
                                        
                </Col>

            </Row>
        </Container>
    </div>
    );
}

export default EmotionalGrowth;
