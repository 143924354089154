import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import styles from './OverallGrowth.module.css'; 
import winergeSummary from '../../Assets/4x/winergeSummary.png'
import arrow from '../../Assets/4x/Arrow.png'

import hurdleJump from '../../Assets/videos/GrowthPlanImage.png'

const OverallGrowthPlan = () =>{
    return (
        <div className={styles.body}>
       

        <Container fluid >
            
            <Row >
            <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"5vh", paddingLeft:"4vw"}}>
                    <h2 className={styles.winergeSummaryHeadline}> <span style={{color:"#FBB03B"}}>Growth Process</span></h2>
                    </div>
                    
                <Col lg={6} style={{ paddingLeft:"4vw"}} className={`${styles.HandlingContent} order-lg-1 order-3`}
>
                    

                    <div>
            <ul>
                <li className={styles.winergeSummaryContent} style ={{ paddingTop:"2.5vh"}} >Your Success Multiplier uses your child's GQI to build a tailored growth plan - focusing on what will make the biggest difference</li>
                <li className={styles.winergeSummaryContent}>Your child spends 60 mins per day on WinGS; it's a healthy balance of interactive lessons and self-directed activities</li>
                <li className={styles.winergeSummaryContent}>Our WinGS Bot provides 24/7 guidance for immediate challenges, supporting your child every step of the way </li>
                <li className={styles.winergeSummaryContent}>Weekly check-ins with the success multiplier ensure continuous assessment and progress tracking.</li>
                <li className={styles.winergeSummaryContent}>The growth plan adapts along side your child's evolving needs.</li>
            </ul>
        </div>

        {/* <div style={{ display:"flex", justifyContent:"flex-start", paddingTop:"12vh" }}>
                    <h4> <span style={{color:"#FBB03B"}}> In Smart-Machine Era,</span> <span style={{color:"white"}}>ability to</span> </h4>
                    </div> */}
                                        
                </Col>

                <Col lg={6} className="order-lg-1 order-1" style= {{paddingTop:"1vh"}}>
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                    <img src={hurdleJump} alt="GIF Explaining WinGS"  className={styles.winGSHurdleSize} />
                    </div>
                    
                </Col>

            </Row>
        </Container>
    </div>
    );
}

export default OverallGrowthPlan;
