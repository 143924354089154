import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './WhoThisMightBeFor.module.css'

const WhoThisMightBeFor = () =>{

    return(   

        <div className={styles.body}>
        
        <h2 style={{textAlign:"center", paddingTop:"2vh", paddingBottom:"5vh", color:"black", fontWeight:"bold"}} className={styles.textFont}>WinGS may not be a GOOD FIT if:</h2>

        <Container  >
            <Row className="align-items-center">
                <Col lg={4}>
                    <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center"}} className={`${styles.shadow} ${styles.border}`}>
                    <h5>The cost of program is prohibitive for your family.</h5>
                    </div>
                </Col>
                <Col lg={4}>
                <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center"}} className={`${styles.shadow} ${styles.border}`}>
                    <h5>You are expecting over-night changes and results.</h5>
                    </div>
                </Col>
                <Col lg={4}>
                <div style={{backgroundColor:"white", padding:"18px", borderRadius:"20px", textAlign:"center", }} className={`${styles.shadow} ${styles.border}`}>
                    <h5>You don't fully buy into a growth-based approach over traditional academics.</h5>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
    );
}

export default WhoThisMightBeFor;