import React from 'react';
import { useLocation } from 'react-router-dom';

function ScorePage() {
  const location = useLocation();
  const ecgScore = location.state?.ecgScore;

  return (
    <div style={{minHeight:"60vh"}}>
        <div style={{display:"flex", justifyContent:"center", marginTop:"10vh"}}>
        <h1>Your ECG Score is: {ecgScore}</h1>
        </div>

      <div style={{display:"flex", justifyContent:"center", marginTop:"5vh"}}>
        <h3>For the students of age 14-16, the score greater than 90 is Great! You can read more about ECG Score on the ECG System Section</h3>
      </div>
    </div>
  );
}

export default ScorePage;