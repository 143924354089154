import React, {useState} from "react";

import {Link} from 'react-router-dom';


const ThankYouPage = () =>{
    const [isHovered, setIsHovered] = useState(false);
    return(
        <>
        <div style={{minHeight:"80vh"}}>
        <div style={{display:"flex", justifyContent:"center", marginTop:"5vh"}}>
        <h2 style={{color:"#1B1464"}}>Thank you for submitting, we will get back to you shortly</h2>
        </div>
        <div style={{display:"flex", justifyContent:"center" , marginTop:"5vh", marginBottom:"3vh"}}>
        <h2 style={{color:"#1B1464"}}>Or Just DM us on Whatsapp: 8283056764</h2>
        </div>

        <div style={{display:"flex", justifyContent:"center"}}>
        <Link to="/" style={{backgroundColor: isHovered?"grey":"#1B1464", color:"white", padding:"10px"}} onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}>Go Back</Link>
        </div>
        
        </div>
        </>
    );
}


export default ThankYouPage;