import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TestingServer() {
    const [message, setMessage] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('http://localhost:5000/test');
                setMessage(response.data.message);
            } catch (error) {
                console.error("There was an error fetching the data", error);
            }
        }

        fetchData();
    }, []);

    return (
        <div>
            {/* <h1>{message}</h1> */}
        </div>
    );
}

export default TestingServer;
