import React, { useState, useEffect } from 'react';
import styles from './ECGScore.module.css'
// import { useHistory } from 'react-router-dom';



import questions from './ECGQuestions';
import { redirect, useNavigate } from 'react-router-dom';
const ImageDisplayComponent = ({ image }) => {
  return <div style={{display:"flex", justifyContent:"center"}}>
    <img src={image} alt="Visual Memory Test" style={{ width:"60vw", padding:"5vh" }} />;
  </div>

}



function ECGScore() {

  const [step, setStep] = useState(0);
  const [userInfo, setUserInfo] = useState({ name: '', age: '', contact: '' });
  const [selectedOption, setSelectedOption] = useState("");

  const [showImage, setShowImage] = useState(false);
  const [showQuestion, setShowQuestion]= useState(false);
  const [useEffectRan, setUseEffectRan] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{

    console.log("useEffect triggered");
    console.log("showImage:", showImage);
    console.log("step:", step);


    if (step < questions.length  && questions[step] && questions[step].type === "visual" && !showImage) setShowImage(true);
    if( showImage){
      console.log("Enteredd");
       const timer = setTimeout(()=>{
          console.log("Exit");
            setShowImage(false);
            setUseEffectRan(true);
            setShowQuestion(true);
        },5000); //5 Sec
        return () => clearTimeout(timer);
    }
},[showImage, step]);



const sendToBackend = async (eventData) =>{
  const response = await fetch('http://62.72.56.104:5000/ecgScore', {
    method: 'POST',
    headers:{
        'Content-Type':'application/json',
    },
    body: JSON.stringify(eventData)
});

if(!response.ok){
    throw new Error('Could not save the details');
}
return Promise.resolve("Successful");
}

const calculating = async() =>{
  var totalECGScore = 0;
  var ecgScoreObject = {
    userInfo: userInfo,
    ecgScoreArray:[],
  }
  for(let i=0; i< userOptions.length; i++){
    totalECGScore += questions[i].scoring[userOptions[i]];
    ecgScoreObject.ecgScoreArray.push( questions[i].scoring[userOptions[i]]);
  }

  console.log("userOptions ",userOptions);
  console.log("aboveScore ",totalECGScore);

  await sendToBackend(ecgScoreObject);
  return totalECGScore;
}

const calculateScore = async ()=>{

  // calculating()
  // .then((totalECGScore) => {console.log("Total ECG Score: " + totalECGScore);})
  // .catch((err) => {console.log("Error calculating score:", err)});

  try {
    const totalECGScore = await calculating();
    console.log("Total ECG Score: " + totalECGScore);

    navigate('/myscore', { state: { ecgScore: totalECGScore } });
  } catch (err) {
    console.log("Error calculating score:", err);
  }


};

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    console.log("step -> ", step);
    console.log(e.target.value);
    let opt = -1;
    if(e.target.value ==="option1"){
      opt =0;
    }
    else if(e.target.value ==="option2"){
      opt =1;
    }
    else if(e.target.value ==="option3"){
      opt =2;
    }
    else{
      opt=3;
    }

    if(userOptions.length ===step){
      setUserOptions(userOptions => {
        const updatedOptions = userOptions;
        updatedOptions[step-1] = opt;
        return updatedOptions;
      })
    }
    else{
      setUserOptions(userOptions => {return [...userOptions, opt]});
    }
  };

  const nextStep = () => {
    setSelectedOption("");
    setShowImage(true);
    setShowQuestion(false)
    setStep(prevStep => prevStep + 1);
  };

  if (step === 0) {
    return (
      <div>
        <h2 style={{textAlign:"center", fontWeight:"bold", marginTop:"5vh", color:"#1B1464"}} className={styles.h2}>Want to know your GQI?</h2>
        <h3 style={{textAlign:"center", fontWeight:"bold", marginTop:"5vh", color:"#1B1464"}} className={styles.h3}>We currently have 2 growth parameters: Cognitive and Socio-Emotional</h3>
        <h3 style={{textAlign:"center", fontWeight:"bold",  marginTop:"8vh"}} className={styles.h3}>Enter Your Information</h3>
        <div >
            <div style={{display:"flex", justifyContent:"center"}}>
                    <input style={{height:"5vh", maxWidth:"80vw", minWidth:"30vw", margin:"2vh"}}
                name="name"
                value={userInfo.name}
                onChange={handleUserInfoChange}
                placeholder="Full Name"
                />
            </div>

            <div style={{display:"flex", justifyContent:"center"}}>
                    <input style={{height:"5vh", maxWidth:"80vw", minWidth:"30vw", margin:"2vh"}}
                name="age"
                value={userInfo.age}
                onChange={handleUserInfoChange}
                placeholder="Age - Test & Result varies with age"
                />
            </div>


            <div style={{display:"flex", justifyContent:"center"}}>
                    <input style={{height:"5vh", maxWidth:"80vw", minWidth:"30vw", margin:"2vh"}}
                name="contact"
                value={userInfo.contact}
                onChange={handleUserInfoChange}
                placeholder="WhatsApp No. - Get detailed result"
                />
            </div>
            <div style={{display:"flex", justifyContent:"center", margin:"2vh"}}>
                    <button onClick={nextStep} style={{color:"white", backgroundColor:"red", padding:"1vw"}}>Start Assessment</button>
            </div>

        </div>
      </div>
    );
  } else if (step <= questions.length) {

    // console.log("question Mentioned ", questions[step-1]);
    const currentQuestion = questions[step-1];

    console.log("currentQuestion-> ",currentQuestion);
    if(currentQuestion.type ==="direct"){
      return (
        <div style={{marginLeft:"10vw", marginRight:"10vw"}}>
                <h3 style ={{fontWeight:"bold", marginTop:"6vh"}} className ={styles.h3}>{currentQuestion.passage}</h3>
              <h3 style={{  fontWeight:"bold",  marginTop:"2vh"}} className={styles.h3}>{currentQuestion.question}</h3>

          <div style={{marginTop:"2vh"}}>
                  <label>
                  <input
                      type="checkbox"
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[0]}
                  </label>
          </div>
          <div>
                  <label>
                  <input
                      type="checkbox"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[1]}
                  </label>
          </div>
          <div>
          <label>
                  <input
                      type="checkbox"
                      value="option3"
                      checked={selectedOption === "option3"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[2]}
                  </label>
          </div>
          <div>
          <label>
                  <input
                      type="checkbox"
                      value="option4"
                      checked={selectedOption === "option4"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[3]}
                  </label>
          </div>



          {/* ... Add more options as needed */}
          <div style={{display:"flex", justifyContent:"center", margin:"5vh"}}>
          <button style={{padding:"5px", color:"white", backgroundColor:"#1B1464"}} onClick={nextStep}>Next Question</button>
          </div>

        </div>
      );
    }
    else{

      // START - VISUAL
      return(
        <>


        {showImage && <ImageDisplayComponent image={currentQuestion.img}/>}


        {showQuestion && <div style={{marginLeft:"10vw", marginRight:"10vw"}}>
          <h3 style={{  fontWeight:"bold",  marginTop:"8vh"}} className={styles.h3}>{currentQuestion.question}</h3>
          {/* Sample options for demonstration, adjust as needed */}
          <div style={{marginTop:"2vh"}}>
                  <label>
                  <input
                      type="checkbox"
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[0]}
                  </label>
          </div>
          <div>
                  <label>
                  <input
                      type="checkbox"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[1]}
                  </label>
          </div>
          <div>
          <label>
                  <input
                      type="checkbox"
                      value="option3"
                      checked={selectedOption === "option3"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[2]}
                  </label>
          </div>
          <div>
          <label>
                  <input
                      type="checkbox"
                      value="option4"
                      checked={selectedOption === "option4"}
                      onChange={handleOptionChange}
                  /> {currentQuestion.options[3]}
                  </label>
          </div>



          {/* ... Add more options as needed */}
          <div style={{display:"flex", justifyContent:"center", margin:"5vh"}}>
          <button style={{padding:"5px", color:"white", backgroundColor:"#1B1464"}} onClick={nextStep}>Next Question</button>
          </div>

        </div> }
        {/* //END */}

        </>
      );


    }

  } else {

    return (
      <>
      <div style={{minHeight:"60vh"}}>


      <div style={{display:"flex", justifyContent:"center", marginTop:"10vh"}}>
        <h2 style={{color:"#1B1464"}}>Thank you for completing the assessment!</h2></div>
      <div onClick = {calculateScore} style={{display:"flex", justifyContent:"center", marginTop:"5vh", marginBottom:"15vh"}}>
        <h2 style={{color:"white",  backgroundColor: isHovered?"grey":"#1B1464", padding:"15px", cursor: "pointer",transition: "white 0.3s"}} onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}>Click me to get the Results</h2></div>
        </div>
    </>
  );
}
}

export default ECGScore;