import React from "react";

import styles from './ReviewTemplate.module.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


/*

There are multiple things
name="Kashish Jain" 
title="Father" 
profession="Engineer" 
aboutChild="in class 9th"

*/

const ReviewTemplate = (props) => {
    return(
        <div  className = {styles.body}>
            <h5 className={styles.font} style={{color:"#1B1464"}}>{props.children}</h5>

                <div style={{marginTop:"2vh"}}>
                     <h5 className={styles.font}>{props.name}</h5>
                     <h5 className={styles.font}>{props.title}</h5>
                </div>
           
                   
                  
            
           
           
            
        </div>
    );
}

export default ReviewTemplate;