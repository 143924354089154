import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './TextHover.module.css';

import InstagramLogo from '../../Assets/Instagram.svg'
import LinkedInLogo from '../../Assets/LinkedIn.svg'
import WhatsappLogo from '../../Assets/Whatsapp.svg'

gsap.registerPlugin(ScrollTrigger);

const TextHover = () => {
  const textRef = useRef([]);

  useEffect(() => {
    textRef.current.forEach(textElement => {
      gsap.to(textElement, {
        backgroundSize: '100%',
        ease: 'none',
        scrollTrigger: {
          trigger: textElement,
          start: 'center 80%',
          end: 'center 50%',
          scrub: true,
        },
      });
    });
    
  }, []);

  return (
    <>
    
    <div className={styles.container} style={{marginBottom:"0", padding:"0", paddingLeft:"3vw"}}>
       
      <h1 className={styles.text} ref={el => textRef.current.push(el)}>
        EMAIL US<span className={styles.span}>customerservice@winerge.com</span>
      </h1>
      <h1 className={styles.text} ref={el => textRef.current.push(el)}>
        MESSAGE US<span className={styles.span}>8283056764</span>
      </h1>
      <h1 className={styles.text} ref={el => textRef.current.push(el)}>
        LINKEDIN<span className={styles.span}><a href="https://www.linkedin.com/company/winerge/about/"> <img style={{height:"4vw", marginLeft:"4vw"}} src={LinkedInLogo}/></a></span>
      </h1>
      <h1 className={styles.text} ref={el => textRef.current.push(el)}>
        INSTAGRAM<span className={styles.span}><a href="https://www.instagram.com/thewinerge/" > <img style={{height:"4vw", marginLeft:"4vw"}} src={InstagramLogo}/></a></span>
      </h1>
      <h1 className={styles.text} ref={el => textRef.current.push(el)}>
        WHATSAPP<span className={styles.span}><a  href="https://wa.me/918283056764?text=Hi,%20I%20want%20to%20talk%20regarding%20Winerge" > <img style={{height:"4vw", marginLeft:"4vw"}} src={WhatsappLogo}/></a></span>
      </h1>
    </div>
    </>
  );
}

export default TextHover;
{/* <a href="https://www.linkedin.com/company/winerge/about/"> <img style="height: 50px;" src="./ResourcesReq/LinkedIn.svg"></a>
<a href="https://www.instagram.com/thewinerge/" class="contactLogo"> <img style={{height:"4vw", marginLeft:"4vw"}} src={InstagramLogo}/>></a>
<a  href="https://wa.me/918283056764?text=Hi,%20I%20want%20to%20talk%20regarding%20Winerge" > <img style={{height:"4vw", marginLeft:"4vw"}} src={WhatsappLogo}></a>
 */}
