import React from "react";

import styles from './Footer.module.css'

import TextHover from "../TextHoverEffect/TextHover";

const Footer = ()=>{
    return(
        <>
         
        <TextHover/>
        </>
        
    );
}

export default Footer;