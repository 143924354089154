import React from "react";




import neverLikeThis from '../../Assets/videos/NeverLikeThis.mp4'

import styles from './NeverLikeThis.module.css'

const NeverLikeThis = () =>{

    return(
        <div className={styles.mainContainer}>
            <div className={styles.overlay}></div>
            <video src={neverLikeThis} autoPlay loop muted/>
            
                    <div className={styles.contentBox}>
                <h1 style={{fontWeight:"bold"}}>They have never Lived like this Before.</h1>
            </div>
              
            
        </div>
    );
}


export default NeverLikeThis;