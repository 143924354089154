import React from "react";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import ReviewTemplate from "../ReviewTemplate/ReviewTemplate";
import styles from './Reviews.module.css'

import Button from 'react-bootstrap/Button';

import pf1 from '../../Assets/ReviewPhoto/pf1.jpg'
import pf2 from '../../Assets/ReviewPhoto/pf2.jpg'
import pf3 from '../../Assets/ReviewPhoto/pf3.jpg'
import pf4 from '../../Assets/ReviewPhoto/pf4.jpg'

const handleDragStart = (e) => e.preventDefault();

const items = [
    <ReviewTemplate onDragStart={handleDragStart} role="presentation" name="Mr. Rajeev Verma" title="Father of Aarav, Class 9, Bangalore" img={pf3}>"Being in a competitive environment, my son was always under pressure to excel academically. The AGS has been a game-changer for Aarav. It’s not just about scores, which improved significantly; it’s about developing his emotional resilience and cognitive abilities. I’ve seen him become more focused, handling stress better, and his problem-solving skills have notably improved. The personalized guidance from the Success Multiplier is invaluable, especially given our hectic schedules. Truly, a holistic approach to education!"</ReviewTemplate>,
    <ReviewTemplate onDragStart={handleDragStart} role="presentation" name="Mrs. Sunita Kapoor" title="Mother of Riya, Class 10, Mumbai" img={pf1}>"I was initially skeptical, but the transformation I've seen in Riya is remarkable! The AGS focus on emotional capability has helped her manage anxiety and build self-confidence. Riya has become more proactive in her studies, showing enhanced creativity and analytical skills. The Success Multiplier has been a blessing, providing expert guidance and alleviating my concerns as a busy parent. Riya is not just scoring well; she’s thriving in every aspect!"</ReviewTemplate>,
    <ReviewTemplate onDragStart={handleDragStart} role="presentation" name="Mr. Arvind Patel" title="Father of Yash, Class 9, Ahmedabad" img={pf4}>"With both of us working, finding time to guide Yash was challenging. The AGS expertly guided nurturing has filled that gap perfectly. Yash’s Success Multiplier understands his unique needs and guides him in a way that we couldn’t have managed with our busy schedules. The growth in his emotional stability and cognitive skills is evident. He’s more disciplined, his memory recall has improved, and he’s handling academic pressure like a pro!"</ReviewTemplate>,
    <ReviewTemplate onDragStart={handleDragStart} role="presentation" name="Mrs. Meena Iyer" title="Mother of Ananya, Class 10, Bangalore" img={pf2}>"My ananya was a bright student but struggled with procrastination and distractions. The AGS has been transformative! It’s so much more than an academic tool; it’s a life skill developer. Ananya is more focused, her creative thinking has blossomed, and she’s developed a strong sense of self-belief. The emotional and cognitive balance the system provides is exactly what she needed. I’m confident she’s with the right hands!"</ReviewTemplate>,
  ];

const Reviews = () =>{
    return (
        <>
        <div className= {styles.body}>
        <h2 style={{textAlign:"center", paddingTop:"5vh", color:"#FBB03B"}} className={styles.textFont}>Stories from Parents just like you...</h2>
            <div className={styles.reviewBox}>
            <AliceCarousel autoPlay autoPlayInterval={2000} infinite={true} mouseTracking items={items} />
            </div>
        
            <div style={{paddingTop:"3vh", paddingBottom:"5vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                       
                            <Button onClick={() => { window.location.href='https://drive.google.com/drive/folders/1CwnXc06Jv4vOMY331Jq4tRcETXkbWmOd?usp=drive_link'; }}  className={styles.buttonStyling}>Get Brochure for Class 8-10th</Button>
                           
                    </div>
        </div>
        </>
    );
}

export default Reviews;