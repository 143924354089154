import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import {NavLink} from "react-router-dom";

import styles from './header.module.css'
import winergeLogo from '../../Assets/HoriLogo.png'


import { useMatch } from "react-router-dom";

function Header() {

  const isHomeActive = useMatch('/');
  const isECGSystemActive = useMatch('/ecgsystem');
  const isBlogsActive = useMatch('/blogs');
  const isECGScoreActive = useMatch('/ecgscore');
  const isNewsletterActive = useMatch('/newsletter');
  const isActiveHome = useMatch('/');
  // console.log("I m here ", !!isActive);
  const activeHomeStyles = !!isHomeActive ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  const activeECGSystemStyles = !!isECGSystemActive ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  const activeBlogsStyles = !!isBlogsActive ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  const activeECGScoreStyles = !!isECGScoreActive ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  const activeNewsletterStyles = !!isNewsletterActive ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  const activeHome = !!isActiveHome ? { fontWeight: 'bolder', color:'#f0bf70'} : {};
  return (
    <Navbar expand="lg" className={styles.navbarColor}>
      <Container style = {{marginLeft:'10px'}}>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Brand style = {{marginLeft:'10px'}}>
          <NavLink to='/'>
          <img
              src={winergeLogo}
              className = {styles.logoHeight} 
              
              alt="Winerge Logo"
            />
          </NavLink>
            
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:"#FBB03B"}} />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav >
          <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeHome}  to="/" >Home</NavLink>
          <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeECGSystemStyles}  to="/ecgsystem" >AGS</NavLink>
            <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeBlogsStyles} to="/blogs" >Blogs</NavLink>
            <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeECGScoreStyles} to="/ecgscore" >GQI</NavLink>
            <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeNewsletterStyles} to="/newsletter" >Newsletter</NavLink>
            {/* <NavLink className={`${styles.navLink} ${styles.fontStyle}`} style={activeJoinUsStyles} to="/joinus" >Join us</NavLink> */}
            {/* FBB03B */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;