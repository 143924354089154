import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './PricingTable.module.css';

// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt , faStarAndCrescent , faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const cardData = [
  {
    icon: faStarHalfAlt,
    planName: "Basic",
    price: <s>299</s>,
    features: [
      { icon: faCheck, text: "No Cost" },
      { icon: faCheck, text: "One time Workshop" },
      { icon: faCheck, text: "One-to-One" },
      { icon: faCheck, text: "QnA with Experts" },
      { icon: faCheck, text: "One Time" }
    ],
    href:"/ecgsystem#ECGSystemId"
  },
  {
    icon: faStar ,
    planName: "PREMIUM",
    price: "2987",
    features: [
      { icon: faCheck, text: "Access to all growth trainings" },
      { icon: faCheck, text: "Hardcopy of worksheets" },
      { icon: faCheck, text: "Success Multiplier" },
      { icon: faCheck, text: "Peer group Access" },
      { icon: faTimes, text: "WinGS-GPT(the Bot)" },
      { icon: faTimes, text: "Skill Training(check brochure)" }
    ],
    
  },
  {
    icon: faStarAndCrescent ,
    planName: "ULTIMATE",
    price: "3977",
    features: [
      { icon: faCheck, text: "Access to all growth trainings" },
      { icon: faCheck, text: "Hardcopy of worksheets" },
      { icon: faCheck, text: "Success Multiplier" },
      { icon: faCheck, text: "Peer group Access" },
      { icon: faCheck, text: "WinGS-GPT(the Bot)" },
      { icon: faCheck, text: "Skill Training(check brochure)" }
    ]
  }
];

function PricingTable() {
    return (
        <>
        <div style={{backgroundColor:"whiteshadow"}}>

        
        <h1 style={{textAlign:"center", paddingTop:"30px",fontWeight:"bold", color:"#1B1464"}}>Plans for your child</h1>
      <section className={styles.section}>
        <Container fluid>
          <Container>
            <Row>
              {cardData.map((data, index) => (
                <Col lg={4} key={index} className={`${styles.cardColumn} ${getCardColorClass(index)}`}>
                  <div className={`${styles.card} text-center`}>
                    <div className={styles.title}>
                      <FontAwesomeIcon icon={data.icon} className={styles.faIcon} />
                      <h2>{data.planName}</h2>
                    </div>
                    <div className={styles.price}>
                      <h4><sup>₹</sup>{data.price}</h4>
                    </div>
                    <div className={styles.option}>
                      <ul>
                        {data.features.map((feature, fIndex) => (
                          <li key={fIndex}>
                            <FontAwesomeIcon icon={feature.icon} className={feature.icon === faCheck ? styles.checkIcon : styles.timesIcon} /> 
                            {feature.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <a href="#form">Contact Now</a>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </section>
      </div>
      </>
    );
  }

function getCardColorClass(index) {
  switch (index) {
    case 0:
      return styles.cardColorFirst;
    case 1:
      return styles.cardColorSecond;
    case 2:
      return styles.cardColorThird;
    default:
      return '';
  }
}

export default PricingTable;
