import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './AchieveAnything.module.css';

import starVideo from '../../Assets/videos/StaryWorld.mp4'

const AchieveAnything = () =>{

    return(
        <div className={styles.mainContainer}>
            <div className={styles.overlay}></div>
            <video src={starVideo} autoPlay loop muted/>
            
                    <div className={styles.contentBox}>
                <h1 style={{fontWeight:"bold"}}>So that they become the one they always wanted to be, and achieve what they never imagined.</h1>
            </div>
              
            
        </div>
    );
}

export default AchieveAnything;