import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './WinergeForYou.module.css';
import {Link} from 'react-router-dom'

// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHeart , faLeaf  , faTrophy  , faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const cardData = [
  
  {
    icon: faTrophy ,
    planName: "Pillars of WinGS?",
    features: [
      { text: "Winerge Proprietary AGS(WinGS)" }
    ],
    href:"/ecgsystem#ECGSystemId"
  },
  {
    icon: faHandHoldingHeart  ,
    planName: "What are Growth Parameters?",
    features: [
      { text: "Adaptive Growth System" }    
    ],
    href:"/ecgsystem#WinergeGrowthParams"
    
  },
  {
    icon: faLeaf ,
    planName: "Growth of your child?",
    features: [
      {text: "How WinGS gives the real experience" },
    ],
    href:"/ecgsystem#GrowthCycleId"
  }
];

function WinergeForYou() {
    return (
        
        <div style={{backgroundColor:"whitesmoke"}}>
        <h1 style={{textAlign:"center", paddingTop:"30px",fontWeight:"bold", color:"#1B1464"}}>Learn what Winerge can do for you</h1>
      <section className={styles.section}>
        <Container fluid>
          <Container>
            <Row>
              {cardData.map((data, index) => (
                <Col lg={4} key={index} className={`${styles.cardColumn} ${getCardColorClass(index)}`}>
                  <div className={`${styles.card} text-center`}>
                    <div className={styles.title}>
                      <FontAwesomeIcon icon={data.icon} className={styles.faIcon} />

                      <h2>{data.planName}</h2>
                    </div>
                   
                    <div className={styles.option}>
                      <ul>
                        {data.features.map((feature, fIndex) => (
                          <li key={fIndex}>
                            <FontAwesomeIcon icon={feature.icon} className={feature.icon === faCheck ? styles.checkIcon : styles.timesIcon} /> 
                            {feature.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Link to={data.href} style={{backgroundColor:"#1B1464", color:"white"}}>Click Here</Link>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </section>
      </div>
      
    );
  }

function getCardColorClass(index) {
  switch (index) {
    case 0:
      return styles.cardColorFirst;
    case 1:
      return styles.cardColorSecond;
    case 2:
      return styles.cardColorThird;
    default:
      return '';
  }
}

export default WinergeForYou;
