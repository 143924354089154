import Logo from '../../Assets/Logo.png'
import Step1 from '../../Assets/Step1.png'
import Step2 from '../../Assets/Step2.png'

import v1 from '../../Assets/VisualQuestions/v1.png'
import v2 from '../../Assets/VisualQuestions/v2.png'
import v3 from '../../Assets/VisualQuestions/v3.png'

const questions = [
  {
    type: "direct",
    passage: "",
    question: "Before Beginning, Some Rules",
    options: [
        "Most of the questions have multiple right answers.",
        "Be Honest with yourself.",
        "Don't Answer Based on what you think right answer should be.",
        "Answer based on How you behave & React.",
    ],
    scoring: [0, 0, 0, 0]
  },
  {
    type: "direct",
    passage: "",
    question: "Your friend has been caught cheating on a test, and the teacher asks if you saw anything. What would you do?",
    options: [
        "Immediately tell the teacher what you saw, as honesty is important.",
        "Keep quiet and not say anything to avoid getting involved.",
        "Confront your friend privately, urging them to confess.",
        "Tell the teacher only if pressured, but try to avoid it if possible."
    ],
    scoring: [2, 0, 1, 0]
},
{
  type: "direct",
  passage: "",
  question: "What do you think is the most important thing you should consider when planning for your future?",
  options: [
      "Mention education, career goals, or personal growth as important factors.",
      "Consider the future but with limited depth or focus because you don't know what the future holds.",
      "Not consider the future or give an irrelevant answer.",
      "Provide a vague or unrelated response."
  ],
  scoring: [2, 1, 0, 0]
},
  {
      type: "direct",
      passage: "",
      question: "A graph shows a steady increase in global temperatures over the past century. What can be inferred from this data?",
      options: [
          "The world is experiencing more rainfall.",
          "Global warming is a potential concern.",
          "The number of trees has increased globally.",
          "Winter seasons are longer now."
      ],
      scoring: [0, 2, 0, 0]
  },
 
  {
      type: "direct",
      passage: "",
      question: "In a story where the protagonist faces challenges but always remains optimistic, what theme is likely being explored?",
      options: [
          "The inevitability of fate",
          "The power of perseverance",
          "The dangers of blind trust",
          "The unpredictability of life"
      ],
      scoring: [0, 2, 0, 0]
  },
  {
      type: "direct",
      passage: "",
      question: "A city introduces free public Wi-Fi and subsequently sees an increase in tourists. What can be inferred?",
      options: [
          "The city's hotels are cheaper.",
          "Tourists come to the city for Wi-Fi.",
          "Free Wi-Fi might be an attractive feature for tourists.",
          "The city has more tourist attractions."
      ],
      scoring: [0, 0, 2, 0]
  },
  {
    type: "direct",
    passage: "",
    question: "You've been saving money for a dream vacation, but a limited-time sale for a luxury item you've always wanted is available. What's your reaction?",
    options: [
        "Splurge on the luxury item and postpone the vacation.",
        "Purchase the luxury item but reduce spending on other non-essential items to maintain your savings plan.",
        "Stick to your savings plan for the vacation and ignore the sale.",
        "Buy the luxury item and hope you can save more later."
    ],
    scoring: [0, 0, 2, 0]
  },
 

{
    type: "direct",
    passage: "",
    question: "Your friends want you to skip school and do something you know is against the rules. What do you do?",
    options: [
        "Politely decline and suggest alternative plans that are within the rules.",
        "Go along with your friends to avoid conflict, but not participate in rule-breaking.",
        "Join your friends without question to avoid feeling left out.",
        "Report your friends' plans to a teacher to avoid getting involved."
    ],
    scoring: [2, 1, 0, 0]
},
{
    type: "direct",
    passage: "",
    question: "You have a choice between buying something you really want now or saving your money for a future goal. What would you choose and why?",
    options: [
        "Choose to save for the future goal and provide a logical reason.",
        "Consider both options but lean toward one with reasoning.",
        "Surely choose to buy now because you don't know about the future.",
        "Not consider the choice or give an irrelevant answer."
    ],
    scoring: [2, 1, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "Next Question is Visual Question",
  options: [
      "Be Attentive.",
      "Image will be shown for 5 sec.",
      "Don't cheat.",
      "Be Honest Always."
  ],
  scoring: [0, 0, 0, 0]
},
{
  type: "visual",
  passage: "",
  img:v1,
  question: "Out of Elephant, Crocodile, Tortoise, Deer, Monkey, Cheetah  which options correctly tell about animal/s not present in the picture ",
  options: [
      "Crocodile, Tortoise",
      "Cheetah, Deer, Elephant",
      "Elephant, Tortoise",
      "Crocodile, Tortoise, Deer"
  ],
  scoring: [0, 0, 0, 2]
},

{
  type: "direct",
  passage: "",
  question: "What do you do when you encounter a difficult problem in your studies?",
  options: [
      "Skip it and hope it's not on the test.",
      "Try for a while, then ask a friend for the answer.",
      "Work on it until you find a solution.",
      "Look up the solution immediately."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "You often procrastinate on self-care activities due to anxiety about prioritizing your well-being. How do you address this challenge?",
  options: [
      "Prioritize self-care activities and set aside dedicated time for them in your schedule.",
      "Procrastinate on self-care tasks, believing they can wait until you have more time.",
      "Avoid self-care activities altogether, focusing solely on work and responsibilities.",
      "Engage in self-care activities impulsively whenever you feel overwhelmed."
  ],
  scoring: [2, 0, 0, 0]
},
{
    type: "direct",
    passage: "",
    question: "You receive a message from someone you don't know on social media who wants to be friends. What would you do?",
    options: [
        "Decline the friend request and report the message as spam or suspicious.",
        "Accept the friend request to see who it is and start a conversation.",
        "Accept the request but don't respond to the message.",
        "Share personal information with the stranger out of curiosity."
    ],
    scoring: [2, 0, 1, 0]
},
{
  type: "direct",
  passage: "Sarah gazed out the window, watching the raindrops race down the pane, thinking about the soccer match.",
  question: "Why might Sarah be thinking about the soccer match?",
  options: [
      "She loves watching rain.",
      "The rain might cause the match to be postponed.",
      "She is waiting for a friend.",
      "She has an exam tomorrow."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You are presented with a blank canvas and a variety of art supplies. Your task is to create a piece of artwork that represents the concept of 'time.' Which approach do you think would best represent this abstract concept?",
  options: [
      "Use a combination of colors and abstract shapes to convey the fluidity and ever-changing nature of time.",
      "Paint a clock face with intricate details, emphasizing the precision and measurement of time.",
      "Draw a series of historical events in chronological order to illustrate the progression of time.",
      "Sculpt a three-dimensional hourglass to symbolize the passage of time."
  ],
  scoring: [2, 0, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You are part of a team tasked with designing a spacecraft for interstellar exploration. Which spacecraft concept demonstrates the most creativity and adaptability for this ambitious mission?",
  options: [
      "Develop a modular spacecraft design that can reconfigure itself for various scientific missions, from studying distant exoplanets to collecting cosmic dust.",
      "Propose a traditional spacecraft with advanced propulsion systems for rapid travel between star systems, prioritizing speed over versatility.",
      "Suggest sending a series of smaller, unmanned probes to different star systems, each tailored for specific scientific objectives.",
      "Advocate for a spaceship powered by a theoretical warp drive, enabling near-instantaneous travel to distant galaxies, although it currently exists only in theory."
  ],
  scoring: [2, 0, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You have a major school project due on the same day as an important sports event you've been looking forward to for months. How do you decide what to do?",
  options: [
      "Prioritize the project and work on it, missing the sports event.",
      "Skip the project and attend the sports event, hoping to make up for it later.",
      "Try to negotiate an extension for the project and attend the sports event.",
      "Decide based on the importance of the sports event without considering the project."
  ],
  scoring: [2, 0, 1, 0]
},
{
  type: "direct",
  passage: "",
  question: "Next Question is Visual Question",
  options: [
      "Be Attentive.",
      "Image will be shown for 5 sec.",
      "Don't cheat.",
      "Be Honest Always."
  ],
  scoring: [0, 0, 0, 0]
},

{
  type: "visual",
  passage: "",
  img:v2,
  question: "Tell the right path, pirates have to follow: ",
  options: [
      "Sea Monster -> Lava Rock -> Skull Rock Island -> Mermaid Rock -> Shark Water",
      "Sea Monster -> Lava Rock -> Mermaid Rock -> Skull Rock Island -> Shark Water",
      "Sea Monster -> Mermaid Rock-> Lava Rock -> Skull Rock Island -> Shark Water",
      "Sea Monster -> Skull Rock Island -> Mermaid Rock -> Lava Rock -> Shark Water"
  ],
  scoring: [0, 0, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "You're tasked with creating an artwork that conveys a sense of tranquility and calmness. Which color palette would best achieve this creative goal?",
  options: [
      "Vibrant reds and oranges",
      "Cool blues and soft greens",
      "Bold purples and pinks",
      "Earthy browns and dark grays"
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "A company's sales have decreased, but its advertising expenses have increased. What might be a potential reason for the decrease in sales?",
  options: [
      "The quality of the product has improved.",
      "The advertisements are not effective.",
      "The company has reduced its prices.",
      "The company has expanded its market reach."
  ],
  scoring: [0, 2, 0, 0]
},

{
  type: "direct",
  passage: "",
  question: "You have an important presentation to deliver, and you're anxious about public speaking. How do you typically prepare?",
  options: [
      "Delay preparation, hoping that working under pressure will spark creativity and reduce prolonged anxiety.",
      "Diligently practice and refine your delivery, seeking feedback and making adjustments to improve your performance.",
      "Delegate the task to a colleague or cite other commitments to avoid the responsibility of presenting.",
      "Allocate minimal preparation time, rationalizing that less exposure to the material will reduce anxiety."
  ],
  scoring: [0, 2, 0, 0]
},

{
  type: "direct",
  passage: "",
  question: "If the shadow of an object is falling to its left, and you are facing the object, from which direction is the light coming?",
  options: [
      "From your left",
      "From behind you",
      "From your right",
      "From in front of you"
  ],
  scoring: [0, 0, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "A pie chart displays the distribution of a school's budget. If the largest slice is labeled 'Salaries' and takes up half the chart, what can be inferred?",
  options: [
      "The school prioritizes salaries over other expenses.",
      "The school has too many employees.",
      "The school is overspending on resources.",
      "The school's total budget has decreased."
  ],
  scoring: [2, 0, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "Which object is farthest from you if you have a book on your left, a pen in front of you, and a bag to your right?",
  options: [
      "The book",
      "The pen",
      "The bag",
      "All are at equal distances"
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You're looking for creative inspiration for your next painting. Which approach would be most effective in sparking your creativity?",
  options: [
      "Visiting an art museum to view a diverse range of artworks.",
      "Reading books and articles about famous artists and their techniques.",
      "Attending a live music concert to experience different forms of art simultaneously.",
      "Taking a nature hike to immerse yourself in the beauty of the outdoors."
  ],
  scoring: [0, 0, 2, 0]
},


{
  type: "direct",
  passage: "Helen Keller, despite being blind and deaf from an early age, became an iconic figure. She was known not just for overcoming her disabilities but also for her work as an advocate for women's rights and social issues.",
  question: "Which of the following best describes Helen Keller's achievements?",
  options: [
      "Overcoming blindness and deafness to become a renowned author",
      "Advocacy for women's rights and broader social issues",
      "Establishing schools for the deaf and blind",
      "Writing books on women's rights and social issues"
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "The art gallery showcased an array of paintings, each telling a unique story through colors, shapes, and brushstrokes. Visitors wandered from one masterpiece to another, their imaginations ignited by the creative expressions of the artists.",
  question: "What was the experience of visitors at the art gallery?",
  options: [
      "A sense of boredom and disinterest",
      "Viewing a single, unimpressive painting",
      "Being captivated by the diversity and creativity of the displayed artworks",
      "Losing track of time and getting lost in the gallery"
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "Amelia had meticulously planned her journey, from booking flights and accommodations to creating a detailed itinerary. However, as she embarked on her adventure, unexpected encounters and detours enriched her experience, showing her that sometimes the best moments happen when plans go awry.",
  question: "What was Amelia's attitude toward unexpected events during her journey?",
  options: [
      "She found them to be annoying and frustrating.",
      "She realized they could add value to her experience.",
      "She followed her plans rigidly without deviation.",
      "She never encountered any unexpected events."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You are tasked with creating a new mythology for a fictional world. Which mythical tale demonstrates the most creativity and depth?",
  options: [
      "Craft a story about an ancient forest guardian who weaves dreams into reality, shaping the destiny of those who enter the woods.",
      "Write a legend about a brave warrior who defeats a dragon to protect the kingdom, earning the title of the Dragonbane.",
      "Develop a myth about a mischievous trickster god who plays pranks on the gods and mortals, creating chaos and laughter.",
      "Share a tale of a lost city submerged underwater, where merfolk and sea creatures guard hidden treasures and ancient knowledge."
  ],
  scoring: [2, 0, 0, 0]
},

{
  type: "direct",
  passage: "",
  question: "If you are facing the sunrise, what direction are you looking towards?",
  options: [
      "North",
      "West",
      "South",
      "East"
  ],
  scoring: [0, 0, 0, 2]
},



{
  type: "direct",
  passage: "",
  question: "Imagine you're in a maze with no windows or external references. You've been walking straight ahead, turning left or right at intersections, but you haven't retraced your steps. What kind of shape could the path you've followed form?",
  options: [
      "A perfect circle",
      "A straight line",
      "A spiral",
      "A triangle"
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "While the northern regions experience frigid temperatures, the southern zones bask in warmth. Conversely, as the north welcomes summer, the south braces for winter.",
  question: "What text structure is predominantly used in the passage?",
  options: [
      "Cause and effect",
      "Compare and contrast",
      "Sequence",
      "Problem and solution"
  ],
  scoring: [0, 2, 0, 0]
},


{
  type: "direct",
  passage: "",
  question: "How do you balance ambition with well-being?",
  options: [
      "Prioritize ambition, often at the expense of personal well-being.",
      "Prioritize well-being, even if it means lower achievement.",
      "Strategically push boundaries while ensuring regular rejuvenation.",
      "Focus on short-term pleasures over long-term goals."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "How do you handle tasks that are outside your comfort zone?",
  options: [
      "Focus on your strengths, and avoid them.",
      "Approach them hesitantly, ready to quit at the first sign of failure.",
      "Embrace them as growth opportunities, irrespective of initial failures.",
      "Delegate them to others."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "How do you handle the success of your endeavors?",
  options: [
      "Attribute it solely to your hard work, disregarding other factors.",
      "Consider it a reason to relax and reduce efforts.",
      "Analyze the factors leading to success and how they can be replicated or improved.",
      "Attribute it mostly to luck."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "When you are feeling overwhelmed with schoolwork and extracurricular activities, what is your usual response?",
  options: [
      "Continues to take on more tasks, hoping to catch up.",
      "Seeks help from parents or teachers to manage their schedule.",
      "Prioritizes tasks, delegates when possible, and takes breaks.",
      "Ignores the stress and tries to power through everything."
  ],
  scoring: [0, 2, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "How do you handle a situation where you are unfairly blamed for something you didn't do?",
  options: [
      "Becomes defensive and argues their innocence.",
      "Stays quiet and avoids confrontation.",
      "Acknowledges the situation, asks for evidence, and calmly defends themselves.",
      "Retaliates by blaming someone else."
  ],
  scoring: [0, 1, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "You are in a room with no windows, doors, or markings. How can you determine if you are inside a cube?",
  options: [
      "Measure the angles in the room",
      "Bounce a ball and observe the reflections",
      "Measure the distances between corners of the room",
      "It's impossible to determine without more information"
  ],
  scoring: [0, 0, 2, 0]
},



{
  type: "direct",
  passage: "",
  question: "How will you react when you witness a friend being bullied at school?",
  options: [
      "Stands up for the friend and intervenes to stop the bullying.",
      "Feels uncomfortable but doesn't take any action.",
      "Reports the incident to a trusted adult without confronting the bully.",
      "Joins in on the bullying to fit in with other classmates."
  ],
  scoring: [2, 0, 1, 0]
},
{
  type: "direct",
  passage: "",
  question: "Your best friend is upset about a misunderstanding and is not speaking to you. How do you react?",
  options: [
      "Avoid them until they calm down.",
      "Insist they are overreacting and try to force them to talk.",
      "Reach out to them, express your desire to understand, and apologize for any unintentional harm.",
      "Give them the silent treatment in return."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "Situation: You're in the middle of an intensive study session, and a friend invites you to an impromptu outing. What's your response?",
  options: [
      "Immediately join your friend and take a break from studying.",
      "Politely decline the invitation and explain your commitment to your study session.",
      "Attempt to balance both the outing and studying, sacrificing some study time.",
      "Agree to join your friend but bring your study materials with you."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You've been working on a team project, and the team is facing communication issues and disagreements. What's your approach to resolve the situation?",
  options: [
      "Withdraw from active participation but continue observing.",
      "Assertively express your viewpoints, emphasizing their importance.",
      "Encourage open dialogue, actively listen, and collaboratively seek solutions.",
      "Subtly point out the communication flaws of team members."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "Next Question is Visual Question",
  options: [
      "Be Attentive.",
      "Image will be shown for 5 sec.",
      "Don't cheat.",
      "Be Honest Always."
  ],
  scoring: [0, 0, 0, 0]
},
{
  type: "visual",
  passage: "",
  img:v3,
  question: "Which number was not present? ",
  options: [
      "36",
      "21",
      "96",
      "35"
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "You're faced with a series of unexpected changes in your daily routine, such as a sudden change in your school schedule. How do you generally handle such situations?",
  options: [
      "Get overwhelmed and refuse to adapt to the changes.",
      "Quickly adjust your plans, seek information on the new schedule, and adapt to the changes with a positive attitude.",
      "Blame the school administration for poor communication.",
      "Vandalize school property to protest the changes."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You've been working diligently on a school project, but your group members aren't contributing. How do you handle it?",
  options: [
      "Delegate specific tasks to group members and set deadlines.",
      "Discuss the issue with the teacher and seek guidance.",
      "Organize a meeting to discuss the issues and find ways to make the project successful.",
      "Complete the project independently and inform the teacher of the group's dynamics."
  ],
  scoring: [0, 0, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "You're faced with a series of unexpected changes in your daily routine, such as a sudden change in your school schedule. How do you generally handle such situations?",
  options: [
      "Seek clarification on the changes and adjust your schedule accordingly.",
      "Quickly adjust your plans, seek information on the new schedule, and adapt to the changes with a positive attitude.",
      "Discuss the changes with peers and gather their perspectives and coping strategies.",
      "Evaluate the impact of the changes on your routine and identify areas for adjustment."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "When faced with criticism from a friend, how does the teenager typically react?",
  options: [
      "Initiates a thoughtful conversation to understand their friend's perspective.",
      "Retaliates with criticism of their own.",
      "Retreats from the friendship to avoid further criticism.",
      "Pretends not to care and changes the subject."
  ],
  scoring: [2, 0, 1, 0]
},
{
  type: "direct",
  passage: "",
  question: "When one of your friend cancels plans at the last minute due to a family emergency, how are you most likely going to respond?",
  options: [
      "Feels disappointed but expresses understanding and support.",
      "Accuses the friend of being inconsiderate and avoids talking to them.",
      "Acts indifferent and doesn't acknowledge the situation.",
      "Confronts the friend and demands an explanation."
  ],
  scoring: [2, 0, 0, 0]
},

{
  type: "direct",
  passage: "",
  question: "You often find yourself procrastinating when faced with a difficult decision. How do you typically overcome this tendency?",
  options: [
      "Avoid making the decision altogether and let circumstances dictate the outcome.",
      "Delay the decision and distract yourself with unrelated tasks until you feel more confident.",
      "Seek advice from trusted individuals for making an informed decision.",
      "Make a hasty decision to relieve the pressure of uncertainty."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "You have multiple important tasks to complete, but you're overwhelmed by anxiety about where to start. What's your typical response?",
  options: [
      "Start with the easiest tasks to build confidence before tackling more challenging ones.",
      "Procrastinate on all tasks and hope the anxiety diminishes over time.",
      "Prioritize tasks based on deadlines and tackle them one by one, even if it means facing anxiety.",
      "Avoid the tasks altogether and distract yourself with non-essential activities."
  ],
  scoring: [0, 0, 2, 0]
},
{
  type: "direct",
  passage: "",
  question: "You're overwhelmed by the prospect of networking at a professional event. How do you typically approach the situation?",
  options: [
      "Attend the event with a rehearsed script and a strategic plan to engage with specific individuals who align with your professional goals.",
      "Consider the benefits of networking, but delay your decision to attend until the last minute, hoping to feel more prepared.",
      "Prioritize other commitments and responsibilities as a reason for not attending, avoiding the discomfort of networking.",
      "Participate in the event but remain on the periphery, engaging minimally and observing others' interactions."
  ],
  scoring: [2, 0, 0, 0]
},

{
  type: "direct",
  passage: "",
  question: "You often procrastinate on seeking help or support when facing challenging tasks or situations. How do you typically approach seeking assistance?",
  options: [
      "Procrastinate on seeking help and attempt to handle the challenges independently.",
      "Seek help immediately, acknowledging your limitations and valuing collaboration.",
      "Avoid seeking help altogether, fearing it may reveal your inadequacies.",
      "Seek help sporadically, depending on your mood and circumstances."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You've been working on a long-term project, and it's close to completion. A sudden, exciting social event invitation coincides with your project's final push. What do you do?",
  options: [
      "Drop the project and attend the social event; you deserve a break.",
      "Politely decline the social invitation and prioritize completing your project.",
      "Attend the social event but bring your project work with you to multitask.",
      "Reschedule the social event and commit to finishing the project first."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You have a critical deadline approaching for a project, and you're feeling overwhelmed by anxiety. What do you typically do?",
  options: [
      "Dive into the work immediately, despite the anxiety, and hope it will dissipate as you progress.",
      "Delay starting the project, hoping the anxiety will subside with time.",
      "Seek guidance or support from a mentor or colleague to alleviate your anxiety.",
      "Avoid the project altogether and distract yourself with unrelated tasks."
  ],
  scoring: [0, 0, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "You've been preparing for a school presentation, but the class is canceled due to unforeseen circumstances. How do you typically react to this sudden change in plans?",
  options: [
      "Use the additional time to refine and improve your presentation.",
      "Discuss the cancellation with classmates and share your prepared content.",
      "Reflect on the content you've prepared, consider alternative ways to present it, and maintain enthusiasm for future opportunities.",
      "Seek feedback on your prepared presentation from peers or the teacher."
  ],
  scoring: [0, 0, 2, 0]
},

{
  type: "direct",
  passage: "",
  question: "You're preparing for a crucial exam that determines your academic future. A close friend is going through a tough time and needs your support. What's your response?",
  options: [
      "Prioritize helping your friend and postpone your exam preparation.",
      "Politely explain your academic commitment but offer to support your friend after the exam.",
      "Attempt to balance both your exam preparation and supporting your friend.",
      "Ignore your friend's request for support and focus solely on the exam."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You've committed to a daily exercise routine, but a series of late-night social gatherings are interfering with your sleep schedule. How do you handle this challenge?",
  options: [
      "Skip your exercise routine to attend the social gatherings.",
      "Attend the social gatherings but adjust your exercise routine to earlier in the day.",
      "Find a balance by occasionally attending social gatherings but prioritizing your exercise routine most days.",
      "Prioritize sleep over both social gatherings and exercise."
  ],
  scoring: [0, 0, 0, 2]
},
{
  type: "direct",
  passage: "",
  question: "You've been consistently practicing a musical instrument, but you're feeling stagnant in your progress. What's your approach to reignite your motivation and commitment?",
  options: [
      "Reassess your interest in the instrument and contemplate exploring a different musical avenue.",
      "Proactively seek out a new music teacher or mentor for fresh perspectives, techniques, and personalized guidance.",
      "Maintain your current practice routine, incorporating minor variations and hoping for gradual improvement.",
      "Take a temporary break from playing, reflecting on your goals and expectations for musical advancement."
  ],
  scoring: [0, 2, 0, 0]
},
{
  type: "direct",
  passage: "",
  question: "You're on a strict diet plan to achieve a fitness goal. A tempting dessert is offered to you at a celebration. How do you respond?",
  options: [
      "Give in to the temptation; one dessert won't hurt.",
      "Politely decline the dessert and explain your commitment to your diet.",
      "Eat the dessert but compensate by exercising longer later.",
      "Accept the dessert and then feel guilty about it."
  ],
  scoring: [0, 2, 0, 0]
},


];



  export default questions;


// const questions = [
//   {
//     type: "direct",
//     passage: "",
//     question: "Your friend has been caught cheating on a test, and the teacher asks if you saw anything. What would you do?",
//     options: [
//         "Immediately tell the teacher what you saw, as honesty is important.",
//         "Keep quiet and not say anything to avoid getting involved.",
//         "Confront your friend privately, urging them to confess.",
//         "Tell the teacher only if pressured, but try to avoid it if possible."
//     ],
//     scoring: [2, 0, 1, 0]
// },
// {
//   type: "direct",
//   passage: "",
//   question: "What do you think is the most important thing you should consider when planning for your future?",
//   options: [
//       "Mention education, career goals, or personal growth as important factors.",
//       "Consider the future but with limited depth or focus because you don't know what the future holds.",
//       "Not consider the future or give an irrelevant answer.",
//       "Provide a vague or unrelated response."
//   ],
//   scoring: [2, 1, 0, 0]
// },

// ];

// export default questions;
