import React from "react";
import neverLikeThis from '../../Assets/videos/OceanWater.mp4'

import styles from './TimeToExcel.module.css'

const TimeToExcel = () =>{

    return(
        <div className={styles.mainContainer}>
            <div className={styles.overlay}></div>
            <video src={neverLikeThis} autoPlay loop muted/>
            
                    <div className={styles.contentBox}>
                <h1 style={{fontWeight:"bold"}}>Excellence is now a click away, it's on you to grab it.</h1>
            </div>
              
            
        </div>
    );
}


export default TimeToExcel;