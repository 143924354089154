import React from "react";
import ECGSystemBlog from "../components/Blogs/EcgSystem/EcgSystemBlog";
import Footer from "../components/Footer/Footer";



const ECGSystem = () => {
    return(
        <>
        <ECGSystemBlog/>
        <Footer/>
        </>
    );

}

export default ECGSystem;